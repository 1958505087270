/** \todo Replace with TransferList */
import * as React from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

interface IProps {
  profiles: string[]
  selected: string[]
  setSelected: any
}

export default function TransferProfile(props: IProps) {
  const [checked, setChecked] = React.useState<string[]>([])
  const [left, setLeft] = React.useState<string[]>(props.profiles)
  const [right, setRight] = React.useState<string[]>(props.selected)

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
    props.setSelected(right.concat(left))
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
    props.setSelected(right.concat(leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
    props.setSelected(not(right, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
    props.setSelected([])
  }

  const customList = (items: string[], e2e: string) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto', marginTop: 10 }}>
      <List dense component="div" role="list" data-e2e={'transfer-profile-list-' + e2e}>
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              data-e2e={'transfer-profile-list-' + e2e + '-item'}
            >
              <ListItemIcon>
                <Checkbox
                  data-e2e={'transfer-profile-list-' + e2e + '-item-chk'}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value}
                data-e2e={'transfer-profile-list-' + e2e + '-item-text'}
              />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left, 'left')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            data-e2e="transfer-profiles-btn-right-all"
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            data-e2e="transfer-profiles-btn-right-chk"
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            data-e2e="transfer-profiles-btn-left-chk"
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            data-e2e="transfer-profiles-btn-left-all"
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, 'right')}</Grid>
    </Grid>
  )
}
