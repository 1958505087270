import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { getLicense } from '../common/store/selectors/license'
import { LicenseIndicator } from '../license/LicenseIndicator'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Verified } from '@mui/icons-material'
import { useEffect } from 'react'
import { ServicesStatus } from './ServicesStatus'
import { licenseRequest } from '../common/store/actions/license/action'

export const StatusPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const licenseState = useSelector(getLicense)
  useEffect(() => {
    dispatch(licenseRequest())
  }, [])

  return (
    <>
      <h2>{t('Status')}</h2>
      <hr />
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Verified />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<LicenseIndicator licenseState={licenseState} />}
            secondary={t('license_status')}
          />
        </ListItem>
        <ServicesStatus />
      </List>
    </>
  )
}
