import { createReducer } from '@reduxjs/toolkit'
import {
  cleanLoginState,
  userInfoError,
  userInfoRequest,
  userInfoSuccess,
} from '../actions/user/actions'

interface UserInfoState {
  username: string | null
  id: number | null
  active: boolean | null
  name: string | null
  isLoading: boolean
  multicast_ip?: string
  multicast_port?: number
  mqtt_topic?: string
  success: string
  error: string
}

export const initialState = (): UserInfoState => {
  console.log('LoginInfo init state')

  const auth = JSON.parse(localStorage.getItem('auth') || 'null')

  return {
    username: auth?.username || null,
    id: auth?.id || null,
    active: auth?.active || null,
    name: auth?.name || null,
    isLoading: false,
    success: '',
    error: '',
  }
}

export const userInfoReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(userInfoRequest, (state) => {
      console.log('userInfoRequest')
      state.isLoading = true
    })
    .addCase(userInfoSuccess, (state, { payload }) => {
      console.log('userInfoSuccess')
      state.username = payload.username
      state.name = payload.name
      state.active = payload.active
      state.id = payload.user_id
      state.isLoading = false
      state.mqtt_topic = payload.mqtt_topic
      state.multicast_ip = payload.multicast_ip
      state.multicast_port = payload.multicast_port
      localStorage.setItem('auth', JSON.stringify(state))
    })
    .addCase(userInfoError, (state, { payload }) => {
      console.error('userReducer - userInfoError', payload)
      state.error = payload
      state.username = null
      state.id = null
      state.active = null
      state.name = null
      state.isLoading = false
      state.mqtt_topic = undefined
      state.multicast_ip = undefined
      state.multicast_port = undefined
    })
    .addCase(cleanLoginState, (state) => {
      console.log('cleanLoginState')
      state.username = null
      state.id = null
      state.active = null
      state.name = null
      state.isLoading = false
      state.mqtt_topic = undefined
      state.multicast_ip = undefined
      state.multicast_port = undefined
      localStorage.removeItem('auth')
      document.cookie = 'username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC'
    })
})
