import { createAction } from '@reduxjs/toolkit'
import {
  INotificationTokenRequest,
  IPushSubscriptionRequest,
  IUserInfo,
  LoginInfoRequest,
} from '../../../api/types'
import {
  CLEAN_LOGIN_STATE,
  USER_INFO_ERROR,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_NOTIFICATION_TOKEN_REQUEST,
  USER_PUSH_SUBSCRIPTION_REQUEST,
} from './actionTypes'

export const userInfoRequest = createAction<LoginInfoRequest>(USER_INFO_REQUEST)
export const userInfoSuccess = createAction<IUserInfo>(USER_INFO_SUCCESS)
export const userInfoError = createAction<any>(USER_INFO_ERROR)

export const cleanLoginState = createAction(CLEAN_LOGIN_STATE)

export const userNotificationTokenRequest = createAction<INotificationTokenRequest>(
  USER_NOTIFICATION_TOKEN_REQUEST,
)

export const userPushSubscriptionRequest = createAction<IPushSubscriptionRequest>(
  USER_PUSH_SUBSCRIPTION_REQUEST,
)
