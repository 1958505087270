import { Button, CircularProgress, Menu, MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSSE } from 'react-hooks-sse'
import { useSelector } from 'react-redux'
import { Group } from '../../common/api/types'
import { groupsStates } from '../../common/store/selectors/groups'
import { DeviceEvent } from '../../types'
import { useTranslation } from 'react-i18next'
import { getAPIService } from '../../getServerHost'

const SelectGroup = () => {
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter((device) => device.type !== 'rcu')
  const zonesState = useSelector(groupsStates)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()

  // const [zones, setGroups] = useState<Group[]>([])
  const inStop = !!devices.find((device) => device.state === 'READY')
  const [enableButton, setEnableButton] = useState(true)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (inStop) {
      setEnableButton(true)
    }
  }, [allDevices])

  const onClickGroupHandle = (zone: Group) => {
    setAnchorEl(null)
    console.log(zone)
    setEnableButton(false)
    if (zone) {
      axios.post(`${getAPIService}/actions/all/stop/2`).then(() => {
        axios.post(`${getAPIService}/actions/groups/${zone.id}/2/play`)
      })
    }
  }

  return (
    <Box display="flex" m={4}>
      <Box height={70}>
        <Button
          id="zone-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
          disabled={!enableButton}
        >
          <Box display="flex" alignItems="center">
            {!enableButton && <CircularProgress size={16} color="inherit" />}
            <Typography ml={2}>{t('zone_active_btn')}</Typography>
          </Box>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {zonesState &&
            zonesState.groups.map((zone) => (
              <MenuItem key={zone.id} value={zone.id} onClick={() => onClickGroupHandle(zone)}>
                {zone.name}
              </MenuItem>
            ))}
        </Menu>
      </Box>
    </Box>
  )
}

export default SelectGroup
