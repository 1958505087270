import axios from 'axios'
import { getAPIService } from '../../getServerHost'
import { Room } from '../types'

export const roomCreate = async (payload: Room) => {
  const response = await axios.post(`${getAPIService}/v2/api/rooms`, {
    name: payload.name,
    rcus: payload.rcus,
  })
  return response.data
}
