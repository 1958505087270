import axios from 'axios'
import { useState } from 'react'
import { getAPIService } from '../getServerHost'
import { useTranslation } from 'react-i18next'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Delete, PauseOutlined, PlayArrow } from '@mui/icons-material'
import { IFile } from '../types'
import { Id } from '../hip/types'

interface FilesTablesProps {
  type: string
  files: IFile[]
  handleConfirmDelete: (id: Id) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
}

const FilesTable = ({
  type,
  files,
  handleConfirmDelete,
  open,
  setOpen,
  handleClose,
}: FilesTablesProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const [deviceToDelete, setDeviceToDelete] = useState<number>(null)
  const [audio, setAudio] = useState(new Audio(''))
  const [isPlaying, setIsPlaying] = useState(false)
  const dur_fe = Math.pow(10, 1)

  const handleClick = (id: Id) => {
    setOpen(true)
    setDeviceToDelete(id)
  }

  const playSound = (file: IFile, e: any) => {
    axios({
      url: `${getAPIService}/${type}/play/${file.id}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const audio = new Audio()
      audio.src = url
      setAudio(audio)
      setIsPlaying(true)
      audio.play()
      audio.onended = () => {
        setIsPlaying(false)
        e.target.click()
      }
    })
  }

  const stopSound = () => {
    setIsPlaying(false)
    audio.pause()
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"> {t('name')}</TableCell>
              <TableCell align="center">{t('files_time')}</TableCell>
              <TableCell align="center">{t('state')}</TableCell>
              <TableCell align="center">{t('files_size')}</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {files?.map((file) => (
              <TableRow key={file.id} data-e2e="file-mngr-row" data-id={file.id}>
                <TableCell align="center" component="th" scope="row" data-e2e="file-mngr-cell-name">
                  {file.name}
                </TableCell>
                <TableCell align="center" data-e2e="file-mngr-cell-duration">
                  {Math.floor(file.duration * dur_fe) / dur_fe + ' ' + t('seconds_abbr')}
                </TableCell>
                <TableCell align="center" data-e2e="file-mngr-cell-state">
                  {t('available')}
                </TableCell>
                <TableCell align="center" data-e2e="file-mngr-cell-size">
                  {(file.size / 1024).toFixed(1) + ' KB'}
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    {(hasPrivilege(Privileges.DELETE_MUSIC_FILES) ||
                      hasPrivilege(Privileges.DELETE_MESSAGE_FILES)) && (
                      <Button
                        variant="text"
                        onClick={() => handleClick(file.id)}
                        data-e2e="file-mngr-cell-delete"
                      >
                        <Delete color="error" />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    <Checkbox
                      icon={<PauseOutlined />}
                      checkedIcon={<PlayArrow />}
                      defaultChecked={true}
                      onChange={(e) => {
                        !e.target.checked ? playSound(file, e) : stopSound()
                      }}
                      data-e2e="file-mngr-cell-play"
                      disabled={isPlaying}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="delete-confirm-dlg"
      >
        <DialogTitle id="responsive-dialog-title">{t('files_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('files_delete_ask')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDelete(deviceToDelete)}
            variant="outlined"
            color="secondary"
            data-e2e="delete-confirm-btn"
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="delete-cancel-btn"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FilesTable
