import axios from 'axios'
import { getAPIService } from '../../getServerHost'

export const addProfile = async (payload: any) => {
  const response = await axios.post(`${getAPIService}/user_profile`, {
    user_id: payload.user_id,
    profiles: payload.profiles,
  })
  return response.data
}
