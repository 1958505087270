import ButtonPanel from './ButtonPanel'
import MicOffIcon from '@mui/icons-material/MicOff'
import MicIcon from '@mui/icons-material/Mic'
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { getUserInfo } from '../../common/store/selectors/user'
import { getWebRTCServer } from '../../getServerHost'
import { useSSE } from 'react-hooks-sse'
import { IProcess } from '../../types'

interface IButtonState {
  isOn: boolean
  color?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'disable'
  icon?: React.ReactNode
}

let pc: RTCPeerConnection = null

const sdpFilterCodec = (kind: string, codec: string, realSdp: string) => {
  const allowed = []
  // eslint-disable-next-line no-control-regex
  const rtxRegex = new RegExp('a=fmtp:(\\d+) apt=(\\d+)\r$')
  const codecRegex = new RegExp('a=rtpmap:([0-9]+) ' + escapeRegExp(codec))
  const videoRegex = new RegExp('(m=' + kind + ' .*?)( ([0-9]+))*\\s*$')

  const lines = realSdp.split('\n')

  let isKind = false
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('m=' + kind + ' ')) {
      isKind = true
    } else if (lines[i].startsWith('m=')) {
      isKind = false
    }

    if (isKind) {
      let match = lines[i].match(codecRegex)
      if (match) {
        allowed.push(parseInt(match[1]))
      }

      match = lines[i].match(rtxRegex)
      if (match && allowed.includes(parseInt(match[2]))) {
        allowed.push(parseInt(match[1]))
      }
    }
  }

  const skipRegex = 'a=(fmtp|rtcp-fb|rtpmap):([0-9]+)'
  let sdp = ''

  isKind = false
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('m=' + kind + ' ')) {
      isKind = true
    } else if (lines[i].startsWith('m=')) {
      isKind = false
    }

    if (isKind) {
      const skipMatch = lines[i].match(skipRegex)
      if (skipMatch && !allowed.includes(parseInt(skipMatch[2]))) {
        continue
      } else if (lines[i].match(videoRegex)) {
        sdp += lines[i].replace(videoRegex, '$1 ' + allowed.join(' ')) + '\n'
      } else {
        sdp += lines[i] + '\n'
      }
    } else {
      sdp += lines[i] + '\n'
    }
  }

  return sdp
}

const escapeRegExp = (value: string) => {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

interface IProps {
  widthAndHeight?: number | string
}

const MicrophoneButton = ({ widthAndHeight }: IProps) => {
  const userInfoState = useSelector(getUserInfo)
  const protocol = process.env?.REACT_APP_PAGING_MODE !== 'mqtt' ? 'mqtt' : 'udp'
  const SSEprocess = useSSE<IProcess[]>('messages', [])
  const [channelInUse, setChannelInUse] = useState(false)

  useEffect(() => {
    if (SSEprocess.length > 0) {
      setChannelInUse(true)
    } else {
      setChannelInUse(false)
    }
  }, [SSEprocess])

  const [buttonState, setButtonState] = useState<IButtonState>({
    isOn: false,
    color: 'primary',
    icon: (
      <MicIcon
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    ),
  })

  const createRTCPeerConnection = () => {
    const rtcConfig: RTCConfiguration = {}
    rtcConfig.iceServers = [{ urls: ['stun:stun.l.google.com:19302'] }]
    pc = new RTCPeerConnection(rtcConfig)
    pc.addEventListener('track', (event) => {
      const audio = document.getElementById('audio') as HTMLMediaElement
      audio.srcObject = event.streams[0]
    })
    return pc
  }

  const iceGathering = (resolve: any) => {
    function checkState() {
      if (pc.iceGatheringState === 'complete') {
        pc.removeEventListener('icegatheringstatechange', checkState)
        resolve()
      }
    }
    pc.addEventListener('icegatheringstatechange', checkState)
  }

  const negotiate = () => {
    return pc
      .createOffer()
      .then((offer) => {
        return pc.setLocalDescription(offer)
      })
      .then(() => {
        return new Promise<void>(function (resolve) {
          if (pc.iceGatheringState === 'complete') {
            resolve()
          } else {
            // eslint-disable-next-line no-inner-declarations
            iceGathering(resolve)
          }
        })
      })
      .then(function () {
        const offer = pc.localDescription as any
        offer.sdp = sdpFilterCodec('audio', 'PCMU/8000', offer.sdp)

        return fetch(`${getWebRTCServer}/offer`, {
          body: JSON.stringify({
            sdp: offer.sdp,
            type: offer.type,
            multicast_ip: userInfoState.multicast_ip,
            multicast_port: userInfoState.multicast_port,
            mqtt_topic: userInfoState.mqtt_topic,
            protocol: protocol,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
      })
      .then(function (response) {
        return response.json()
      })
      .then(function (answer) {
        return pc.setRemoteDescription(answer)
      })
      .catch(function (e) {
        alert(e)
      })
  }

  const start = () => {
    const pc = createRTCPeerConnection()
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      (stream) => {
        stream.getTracks().forEach(function (track) {
          pc.addTrack(track, stream)
        })
        return negotiate()
      },
      (err) => {
        alert('Could not acquire media: ' + err)
      },
    )
  }

  const stop = () => {
    // close transceivers
    if (pc.getTransceivers) {
      pc.getTransceivers().forEach(function (transceiver) {
        if (transceiver.stop) {
          transceiver.stop()
        }
      })
    }

    // close local audio / video
    pc.getSenders().forEach(function (sender) {
      sender.track.stop()
    })

    // close peer connection
    setTimeout(function () {
      pc.close()
    }, 500)
  }

  const handleOnClick = () => {
    if (buttonState.isOn) {
      setButtonState({
        isOn: false,
        color: 'primary',
        icon: (
          <MicIcon
            sx={{
              width: '100%',
              height: '100%',
            }}
          />
        ),
      })
      stop()
    } else {
      setButtonState({
        isOn: true,
        color: 'warning',
        icon: (
          <MicOffIcon
            sx={{
              width: '100%',
              height: '100%',
            }}
          />
        ),
      })
      start()
    }
  }

  return (
    <Box mt={2}>
      <audio id="audio" autoPlay={true}></audio>
      <ButtonPanel
        widthAndHeight={widthAndHeight}
        variant="contained"
        color={buttonState.color}
        onClick={handleOnClick}
        disabled={channelInUse}
      >
        {buttonState.icon}
      </ButtonPanel>
    </Box>
  )
}

export default MicrophoneButton
