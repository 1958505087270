import { combineReducers } from 'redux'
import { devicesReducer } from './devices'
import { messagesReducer } from './messages'
import { groupsReducer } from './groups'
import { licenseReducer } from './license'
import { mqttReducer } from './mqtt'
import { userProfileReducer } from './profile'
import { userInfoReducer } from './user'
import { wardsReducer } from '../../../hip/store/reducers/wardsRed'
import { rcusReducer } from '../../../hip/store/reducers/rcus'
import { roomsReducer } from '../../../hip/store/reducers/rooms'
import { hipUsersReducer } from '../../../hip/store/reducers/hip_users'
import { tasksReducer } from './tasks'
import { filesReducer } from './files'

const root = combineReducers({
  messages: messagesReducer,
  groups: groupsReducer,
  userProfile: userProfileReducer,
  devices: devicesReducer,
  userInfo: userInfoReducer,
  mqtt: mqttReducer,
  license: licenseReducer,
  files: filesReducer,
  wards: wardsReducer,
  rooms: roomsReducer,
  rcus: rcusReducer,
  hipUsers: hipUsersReducer,
  tasks: tasksReducer,
})

export default root
