import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { CircularProgress, Grid } from '@mui/material'
import { CommonContent } from '../../components/CommonContent'
import DetailContainer from '../components/DetailContainer'
import { hipUsersState } from '../store/selectors/hipUsers'
import { useSnackbar } from 'notistack'
import { hipUserCleanErrorRequest, hip_userDetailRequest } from '../actions/hipUsers/actions'
import { HipUserFormValues } from '../types'
import HipUserForm from './HipUserForm'
import { userIcon } from './utilsHipUser'

interface HipUserDetailProps {
  userType: string
}

const HipUserDetail = ({ userType }: HipUserDetailProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { hipUser, error, isLoading } = useSelector(hipUsersState)
  const initialValues: HipUserFormValues = {
    username: hipUser.username,
    name: hipUser.name,
  }

  useEffect(() => {
    dispatch(hip_userDetailRequest(Number(id)))
  }, [])

  useEffect(() => {
    if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(hipUserCleanErrorRequest())
    }
  }, [error])

  const hipUserToRender = {
    user_id: hipUser.user_id,
    access_key:
      hipUser.access_key && hipUser.access_key.key ? hipUser.access_key.key : 'No proximity card',
    name: hipUser.name,
    enabled: hipUser.access_key && hipUser.access_key.enabled ? 'true' : 'false',
    username: hipUser.username,
    mqtt_topic: hipUser.mqtt_topic,
    active: hipUser.active ? 'true' : 'false',
  }

  const objectEntries = Object.entries(hipUserToRender)

  return (
    <CommonContent title={`${userType}_detail`} titleIcon={userIcon(userType)}>
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          <Grid container direction={'column'} alignContent={'center'} spacing={2} mb={4}>
            <Grid item xs={6}>
              <DetailContainer
                title={`${userType}_detail`}
                object={objectEntries}
                data-e2e="nrs-item"
              />
            </Grid>
          </Grid>
          {hasPrivilege(Privileges.EDIT_NURSES) ||
            (hasPrivilege(Privileges.EDIT_MAINTENANCE) && (
              <HipUserForm initialValues={initialValues} setOpen={() => true} userType={userType} />
            ))}
        </>
      )}
    </CommonContent>
  )
}

export default HipUserDetail
