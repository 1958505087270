import { LocalHospital, Handyman } from '@mui/icons-material'
// import { HipIcon } from '../components/HipIcon'
// case 'nurses': return <HipIcon viewMode="large-icon" image={'NURSE'} size={32} fill={'white'}/>

export const userIcon = (userType: string) => {
  switch (userType) {
    case 'nurses':
      return <LocalHospital style={{ color: 'white' }} />
    case 'maintenance':
      return <Handyman style={{ color: 'white' }} />
    default:
      break
  }
}
