import { useState } from 'react'
import { Checkbox, Grid, FormControlLabel, FormGroup, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IpDevice, Tasks } from '../types'

interface IProps {
  items: (Tasks | IpDevice)[]
  selectedItems: number[]
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>
}

const CheckboxSelector = ({ items, selectedItems, setSelectedItems }: IProps) => {
  const { t } = useTranslation()
  const [selectAll, setSelectAll] = useState(false)
  const isSelected = (itemId: number) => selectedItems.includes(itemId)

  const toggleSelection = (itemId: number) => {
    const updatedSelected = isSelected(itemId)
      ? selectedItems.filter((item) => item !== itemId)
      : [...selectedItems, itemId]
    setSelectedItems(updatedSelected)
  }

  const handleSelectAll = () => {
    const updatedSelected = selectAll ? [] : items.map((item) => item.id)
    setSelectedItems(updatedSelected)
    setSelectAll(!selectAll)
  }

  const CheckedItems = () => {
    return (
      <Paper sx={{ width: 200, height: 230, overflow: 'auto', padding: '25px' }}>
        <FormGroup>
          {items.map((item) =>
            isSelected(item.id) ? (
              <FormControlLabel
                key={item.id}
                control={<Checkbox checked={true} onChange={() => toggleSelection(item.id)} />}
                label={isIpDevice(item) ? item.description : item.name}
              />
            ) : null,
          )}
        </FormGroup>
      </Paper>
    )
  }

  const UncheckedItems = () => {
    return (
      <Paper sx={{ width: 200, height: 230, overflow: 'auto', padding: '25px' }}>
        <FormGroup>
          {items.map((item) =>
            !isSelected(item.id) ? (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={false}
                    onChange={() => toggleSelection(item.id)}
                    data-e2e="checkbox-item"
                  />
                }
                label={isIpDevice(item) ? item.description : item.name}
              />
            ) : null,
          )}
        </FormGroup>
      </Paper>
    )
  }

  const isIpDevice = (item: Tasks | IpDevice): item is IpDevice => {
    return (item as IpDevice).description !== undefined
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
              label={t('select_all')}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3}>
          <Typography align="left" variant="subtitle1">
            {isIpDevice ? t('devices') : t('holidays')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <UncheckedItems />
        </Grid>
        <Grid item xs={3}>
          <CheckedItems />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CheckboxSelector
