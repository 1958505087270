import { createAction } from '@reduxjs/toolkit'
import {
  WARDS_CLEAN_ERROR_REQUEST,
  WARDS_CLEAN_SUCCESS_REQUEST,
  WARDS_ERROR,
  WARDS_REQUEST,
  WARDS_SUCCESS,
} from './actionTypes'
import { Ward } from '../../types'

export const wardsRequest = createAction(WARDS_REQUEST)
export const wardsSuccess = createAction<Ward[]>(WARDS_SUCCESS)
export const wardsError = createAction<any>(WARDS_ERROR)

export const wardsCleanSuccessRequest = createAction(WARDS_CLEAN_SUCCESS_REQUEST)
export const wardsCleanErrorRequest = createAction(WARDS_CLEAN_ERROR_REQUEST)
