import RoomButton from './RoomButton'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { roomsStates } from '../store/selectors/rooms'
import { useSSE } from 'react-hooks-sse'
import { TModeView, TDeviceSize } from '../../types'
import { IRcu, roomId } from '../types'
import { Box, Grid, MenuItem, Select } from '@mui/material'
import { getStyle } from '../../components/DevicesViewStyle'
import { useDispatch } from 'react-redux'
import { roomsRequest } from '../actions/rooms/actions'

interface IProps {
  isDesktopMode: boolean
  onlyEvents: boolean
}

const RoomsViews = ({ isDesktopMode, onlyEvents }: IProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(roomsRequest({ limit: '200', page: '1' }))
  }, [dispatch])

  const { rooms } = useSelector(roomsStates)
  const devices = useSSE<IRcu[]>('devices', [])
  const rcus = devices.filter((device) => device.type === 'rcu')

  const [viewMode, setViewMode] = useState<TModeView>('large-icon')
  const [deviceSize, setDeviceSize] = useState<TDeviceSize>(120)

  useEffect(() => {
    switch (viewMode) {
      case 'large-icon':
        setDeviceSize(!isDesktopMode ? 90 : 120)
        break
      case 'medium-icon':
        setDeviceSize(!isDesktopMode ? 72 : 100)
        break
      case 'small-icon':
        setDeviceSize(!isDesktopMode ? 64 : 72)
        break
      case 'list':
        setDeviceSize(!isDesktopMode ? 32 : 32)
        break
      default:
        break
    }
  }, [viewMode, isDesktopMode])

  useEffect(() => {
    console.log(rcus)
  }, [devices])

  const style = getStyle(viewMode)

  const filteredRcus = (id: roomId) => {
    return rcus?.filter((rcu) => rcu.room === id)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box border={'1px solid grey'} borderRadius="5px">
          {/* <Box display="flex" flexWrap="wrap" border="1px solid #ccc" borderRadius="5px" mx={20}> */}
          <Box m={5}>
            <Select
              label="View"
              defaultValue={viewMode}
              size="small"
              onChange={(event) => setViewMode(event.target.value as TModeView)}
              data-e2e="viewModeSelect"
            >
              <MenuItem value="large-icon">Large Icon</MenuItem>
              <MenuItem value="medium-icon">Medium Icon</MenuItem>
              <MenuItem value="small-icon">Small Icon</MenuItem>
              <MenuItem value="list">List</MenuItem>
            </Select>
          </Box>
          <Box
            data-e2e="device-list"
            display="flex"
            justifyContent="center"
            width="100%"
            flexWrap="wrap"
          >
            <Box sx={style.layoutStyle} data-e2e="rcu-list">
              {rooms.items.map((room) => (
                <RoomButton
                  key={room.id}
                  room={room}
                  rcus={filteredRcus(room.id)}
                  deviceSize={deviceSize}
                  onlyEvents={onlyEvents}
                  viewMode={viewMode}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default RoomsViews
