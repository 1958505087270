import axios from 'axios'
import { getAPIService } from '../../../getServerHost'
import { PageSet } from '../../../hip/types'

export const getDevices = async (payload: { pageSet: PageSet; type: string }) => {
  const { limit, page } = payload.pageSet
  const { type } = payload
  const response = await axios.get(
    `${getAPIService}/v2/api/ip-devices/${type}?limit=${limit}&page=${page}`,
  )
  return response.data
}

export const resetDevices = async () => {
  const response = await axios.post(`${getAPIService}/action/devices/reset`)
  return response.data
}

export const getDevicesTypes = async () => {
  const response = await axios.get(`${getAPIService}/v2/api/ip-devices/devices/types`)
  return response.data
}
