import { createReducer } from '@reduxjs/toolkit'
import { Ward } from '../../types'
import { wardsError, wardsRequest, wardsSuccess } from '../../actions/wards/actions'

interface WardsState {
  wards: Ward[]
  isLoading: boolean
  succsess: string
  error: string
}

export const initialState = (): WardsState => ({
  wards: [],
  isLoading: false,
  succsess: '',
  error: '',
})

export const wardsReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(wardsRequest, (state) => {
      state.wards = []
      state.isLoading = true
    })
    .addCase(wardsSuccess, (state, { payload }) => {
      state.wards = payload
      state.isLoading = false
    })
    .addCase(wardsError, (state, { payload }) => {
      console.error('wardsReducer - wardsError', payload)
      state.wards = []
      state.isLoading = false
      state.error = payload
    })
})
