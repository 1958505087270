import { SvgIcon } from '@mui/material'
import { TModeView } from '../../types'
import { HipIconType } from '../types'
import { ReactComponent as Assigned } from './hip_icons/assigned.svg'
import { ReactComponent as Bathroom_call } from './hip_icons/bathroom_call.svg'
import { ReactComponent as Bathroom_ignored } from './hip_icons/bathroom_ignored.svg'
import { ReactComponent as Bathroom_req_presence } from './hip_icons/bathroom_requires_presence.svg'
import { ReactComponent as Bed_call } from './hip_icons/bed_call.svg'
import { ReactComponent as Code_blue } from './hip_icons/codeblue.svg'
import { ReactComponent as Doctor } from './hip_icons/doctor.svg'
import { ReactComponent as Error } from './hip_icons/error.svg'
import { ReactComponent as Exit } from './hip_icons/exit.svg'
import { ReactComponent as Ignored } from './hip_icons/ignored.svg'
import { ReactComponent as Login } from './hip_icons/login.svg'
import { ReactComponent as Message_Sent } from './hip_icons/message_sent.svg'
import { ReactComponent as NurseIcon } from './hip_icons/nurse.svg'
import { ReactComponent as Maintenance } from './hip_icons/maintenance.svg'
import { ReactComponent as Ok } from './hip_icons/Ok.svg'
import { ReactComponent as Options } from './hip_icons/options.svg'
import { ReactComponent as Spoken } from './hip_icons/spoken.svg'
import { FunctionComponent, SVGProps } from 'react'

interface IInfo {
  file: FunctionComponent<SVGProps<SVGSVGElement>> // ReactElement<any, string | JSXElementConstructor<any>>
  fill?: string
  stroke?: string
}

const Info: { [key in HipIconType]: IInfo } = {
  OK: {
    file: Ok,
  },
  ERROR: {
    file: Error,
  },
  BED_CALL: {
    file: Bed_call,
  },
  IGNORED: {
    file: Ignored,
  },
  BATHROOM_CALL: {
    file: Bathroom_call,
  },
  BATHROOM_IGNORED: {
    file: Bathroom_ignored,
  },
  CODE_BLUE: {
    file: Code_blue,
  },
  MESSAGE_SENT: {
    file: Message_Sent,
  },
  SPOKEN: {
    file: Spoken,
  },
  NURSE: {
    file: NurseIcon,
  },
  MAINTENANCE: {
    file: Maintenance,
  },
  ASSIGNED: {
    file: Assigned,
  },
  BATHROOM_REQ_PRESENCE: {
    file: Bathroom_req_presence,
  },
  DOCTOR: {
    file: Doctor,
  },
  LOGIN: {
    file: Login,
  },
  EXIT: {
    file: Exit,
  },
  OPTIONS: {
    file: Options,
  },
}

export interface HipIconProps {
  'data-e2e'?: any
  size?: 32 | 64 | 72 | 90 | 100 | 120 | 200
  viewMode?: TModeView
  image: HipIconType
  fill?: string
}

export function HipIcon({ image, size, fill }: HipIconProps) {
  const { file } = Info[image]
  const pathStyles: React.CSSProperties = {
    fill: fill ?? '#1d98f0',
  }
  return <SvgIcon component={file} inheritViewBox sx={{ fontSize: size ?? 64, ...pathStyles }} />
}
