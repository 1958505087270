import { AppBar, Box, CssBaseline, Drawer, Toolbar, Typography } from '@mui/material'
import MenuBar from './MenuBar'
import IconButton from '@mui/material/IconButton'
import { AccountCircle } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getUserInfo } from './common/store/selectors/user'
import Clock from './components/Clock'

interface IProps {
  children: React.ReactNode
}
const handleProfileMenuOpen = () => !0

const AppHeader = ({ children }: IProps) => {
  const { username } = useSelector(getUserInfo)

  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}
          >
            Cloud Paging
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Clock />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle sx={{ marginRight: '0.7em' }} />
              <Typography variant="h6" noWrap component="div">
                {username}
              </Typography>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <MenuBar />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

export default AppHeader
