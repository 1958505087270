import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { Acces_key, deletedItem, User_Key } from '../../types'
import { User } from '../../../types'
import {
  keyCreateError,
  keyCreateRequest,
  keyCreateSuccess,
  keyUpdateError,
  keyUpdateRequest,
  keyUpdateSuccess,
  hipUserCleanErrorRequest,
  hip_usersKeysRequest,
  hip_usersKeysSuccess,
  hip_usersKeysError,
  hip_userCreateRequest,
  hip_userCreateSuccess,
  hip_userCreateError,
  hip_userUpdateRequest,
  hip_userUpdateSuccess,
  hip_userUpdateError,
  hip_userDeleteRequest,
  hip_userDeleteSuccess,
  hip_userDeleteError,
  keyDeleteError,
  keyDeleteSuccess,
  keyDeleteRequest,
  hip_userDetailRequest,
  hip_userDetailSuccess,
  hip_userDetailError,
} from '../../actions/hipUsers/actions'

interface NursesState {
  hipUsers: User_Key[]
  hipUser: User
  isLoading: boolean
  error: string
}

export const initialState = (): NursesState => ({
  hipUsers: [],
  hipUser: {
    id: null,
    username: '',
    password: '',
    name: '',
    active: null,
    user_id: null,
    access_key: null,
    mqtt_topic: '',
  },
  isLoading: false,
  error: '',
})

export const hipUsersReducer = createReducer(initialState(), (builder) => {
  builder.addCase(hipUserCleanErrorRequest, (state) => {
    state.error = ''
  })
  builder.addCase(hip_userCreateRequest, (state) => {
    state.isLoading = true
  })
  builder.addCase(hip_userCreateSuccess, (state, { payload }: PayloadAction<User>) => {
    const hipUser: User_Key = {
      id: payload.user_id,
      username: payload.username,
      name: payload.name,
      active: payload.active,
      access_key: payload.access_key,
    }
    state.hipUsers.unshift(hipUser)
    state.isLoading = false
  })
  builder.addCase(hip_userCreateError, (state, { payload }) => {
    console.error('hipUserssReducer - hip_userCreateError', payload)
    state.error = payload
    state.isLoading = false
  })
  builder.addCase(hip_userUpdateRequest, (state) => {
    state.isLoading = true
  })
  builder.addCase(hip_userUpdateSuccess, (state, { payload }: PayloadAction<User_Key>) => {
    const { id, username, name, active, access_key } = payload
    const updatedNurse: User = {
      id,
      username,
      password: state.hipUser.password,
      name,
      active,
      access_key,
      mqtt_topic: state.hipUser.mqtt_topic,
    }
    state.hipUser = updatedNurse
    state.hipUsers = state.hipUsers.map((hipUser) => (hipUser.id === id ? payload : hipUser))
    state.isLoading = false
  })
  builder.addCase(hip_userUpdateError, (state, { payload }) => {
    console.error('hipUserssReducer - hip_userUpdateError', payload)
    state.error = payload
    state.isLoading = false
  })
  builder.addCase(hip_userDeleteRequest, (state) => {
    state.isLoading = true
  })
  builder.addCase(hip_userDeleteSuccess, (state, { payload }: PayloadAction<number>) => {
    state.hipUsers = state.hipUsers.filter((hipUser) => hipUser.id !== payload)
    state.isLoading = false
  })
  builder.addCase(hip_userDeleteError, (state, { payload }) => {
    console.error('hipUserssReducer - hip_userDeleteError', payload)
    state.error = payload
    state.isLoading = false
  })
  builder.addCase(hip_usersKeysRequest, (state) => {
    state.hipUsers = []
    state.isLoading = true
  })
  builder.addCase(hip_usersKeysSuccess, (state, { payload }: PayloadAction<User_Key[]>) => {
    state.hipUsers = payload
    state.isLoading = false
  })
  builder.addCase(hip_usersKeysError, (state, { payload }) => {
    console.error('hipUserssReducer - hip_usersKeysError', payload)
    state.error = payload
    state.isLoading = false
  })
  builder.addCase(hip_userDetailRequest, (state) => {
    state.hipUser = initialState().hipUser
    state.isLoading = true
  })
  builder.addCase(hip_userDetailSuccess, (state, { payload }: PayloadAction<User>) => {
    state.hipUser = payload
    state.isLoading = false
  })
  builder.addCase(hip_userDetailError, (state, { payload }) => {
    console.error('hipUserssReducer - hip_userDetailError', payload)
    state.error = payload
    state.isLoading = false
  })
  builder.addCase(keyCreateRequest, (state) => {
    state.isLoading = true
  })
  builder.addCase(keyCreateSuccess, (state, { payload }: PayloadAction<Acces_key>) => {
    const hip_user = payload.user
    const hipUserUpdated = {
      id: hip_user.id,
      username: hip_user.username,
      name: hip_user.name,
      active: true,
      access_key: { id: payload.id, key: payload.key, enabled: payload.enabled },
    }
    state.hipUsers = state.hipUsers.map((hipUser) =>
      hipUser.id === hip_user.id ? hipUserUpdated : hipUser,
    )
    state.isLoading = false
  })
  builder.addCase(keyCreateError, (state, { payload }) => {
    console.error('hipUserssReducer - keyCreateError', payload)
    state.error = payload.message
    state.isLoading = false
  })
  builder.addCase(keyUpdateRequest, (state) => {
    state.isLoading = true
  })
  builder.addCase(keyUpdateSuccess, (state, { payload }: PayloadAction<Acces_key>) => {
    const hip_user = payload.user
    const hipUserUpdated = {
      id: hip_user.id,
      username: hip_user.username,
      name: hip_user.name,
      active: true,
      access_key: { id: payload.id, key: payload.key, enabled: payload.enabled },
    }
    state.hipUsers = state.hipUsers.map((hipUser) =>
      hipUser.id === hip_user.id ? hipUserUpdated : hipUser,
    )
    state.isLoading = false
  })
  builder.addCase(keyUpdateError, (state, { payload }) => {
    console.error('hipUserssReducer - keyUpdateError', payload)
    state.error = payload
    state.isLoading = false
  })
  builder.addCase(keyDeleteRequest, (state) => {
    state.isLoading = true
  })
  builder.addCase(keyDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
    const deletedKey = Number(payload.id)
    const hipUser = state.hipUsers.find(
      (hipUser) => hipUser.access_key && hipUser.access_key.id === deletedKey,
    )
    const hipUserUpdated = {
      id: hipUser.id,
      username: hipUser.username,
      name: hipUser.name,
      active: hipUser.active,
    }
    state.hipUsers = state.hipUsers.map((hipUser) =>
      hipUser.id === hipUserUpdated.id ? hipUserUpdated : hipUser,
    )
    state.isLoading = false
  })
  builder.addCase(keyDeleteError, (state, { payload }) => {
    console.error('hipUserssReducer - keyDeleteError', payload)
    state.error = payload.message
    state.isLoading = false
  })
})
