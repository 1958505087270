import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DetailItem from './DitailItem'

interface Iprops {
  title: string
  object: [string, string | number][]
  'data-e2e'?: string
}

const DetailContainer = ({ title, object, ...props }: Iprops) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={3} style={{ padding: '25px' }}>
      <Grid item textAlign={'center'}>
        <Typography variant="h6" mb={3}>
          {t(title)}
        </Typography>
      </Grid>
      <Grid container item spacing={2} textAlign={'center'}>
        {object.map(
          ([key, value]) =>
            value !== undefined &&
            value !== null && (
              <DetailItem key={key} label={key} value={value} data-e2e={props['data-e2e']} />
            ),
        )}
      </Grid>
    </Paper>
  )
}

export default DetailContainer
