import { ButtonTypeMap } from '@mui/material'
import { HipIcon, HipIconProps } from './HipIcon'

interface IProps extends HipIconProps {
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
  onClick?: () => void
  variant?: ButtonTypeMap['props']['variant']
  size?: 32 | 64 | 72 | 90 | 100 | 120 | 200
  // color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'disable'
}

export default function HipButton({ ...props }: IProps) {
  return (
    <>
      <HipIcon {...props} />
    </>
  )
}
