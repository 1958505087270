import React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper, Theme, ThemeProvider, Link } from '@mui/material'
import { getProductName } from './Utils'
import theme from './styles/theme'

const providerSignInImage = process.env?.REACT_APP_PROVIDER_SIGNIN_IMAGE_URL || '/wanpage.jpg'
const providerName = process.env?.REACT_APP_PROVIDER_NAME || 'Surix S.R.L'
const providerUrl = process.env?.REACT_APP_PROVIDER_URL || 'https://surix.net/'

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

interface IProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, location: string) => void
  location?: string
}

export default function SignIn(props: IProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
          <Box p={'10%'} mt={'10vh'}>
            <Typography component="h1" variant="h5" textAlign="center">
              {getProductName()}
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(event) => props.handleSubmit(event, props.location)}
            >
              <TextField
                data-e2e="username"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label={t('username')}
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                data-e2e="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('password')}
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                data-e2e="login"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('signin')}
              </Button>
            </form>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href={providerUrl}>
              {providerName}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={false}
          sm={6}
          md={8}
          sx={{
            backgroundImage: `url(${providerSignInImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'fit',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  )
}
