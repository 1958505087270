import { useDispatch, useSelector } from 'react-redux'
import TransferList from '../components/TransferList'
import React, { useEffect } from 'react'
import { IpDevice } from '../types'
import { devicesState } from '../common/store/selectors/devices'
import { ipdevicesRequest } from '../common/store/actions/devices/actions'

export default function DeviceTransferList() {
  const dispatch = useDispatch()
  const { ipDevices } = useSelector(devicesState)
  const devices = ipDevices.items as IpDevice[]
  const [list, setList] = React.useState<IpDevice[] | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resultList, setResult] = React.useState<IpDevice[]>([])

  useEffect(() => {
    if (list === null) {
      // It's null only the first time
      // console.log('pre-devices', devState.devices)
      dispatch(ipdevicesRequest({ limit: '200', page: '1' }))
    }
    if (devices != list) {
      // console.log('post-devices', devices)
      setList(devices)
    }
  }, [ipDevices, list, setList])

  return (
    <TransferList
      list={devices?.map((device) => ({
        id: device.id,
        name: device.description,
        description: device.device_id,
      }))}
      selected={[]}
      setSelected={setResult}
    />
  )
}
