import axios from 'axios'
import { getAPIService } from '../../getServerHost'
import { KeyFormValues } from '../types'

export const keyCreate = async (payload: KeyFormValues) => {
  const response = await axios.post(`${getAPIService}/v2/api/access-keys`, {
    key: payload.key,
    user_id: payload.user_id,
  })
  return response.data
}
