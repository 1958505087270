import { useTranslation } from 'react-i18next'
import { CommonContent } from '../../components/CommonContent'
import { MeetingRoom } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { EditViewModeE2E, IEntityViewMode } from '../../Utils'
import { Ward } from '../types'
import DeviceTransferList from '../../devices/DeviceTransferList'

export const WardCRUD = () => {
  const { t } = useTranslation()
  const viewMode = IEntityViewMode.VIEW
  const e2eprefix = 'zones-' + EditViewModeE2E[viewMode] + '-'
  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // \todo
  }

  const row: Ward = null // \todo

  return (
    <CommonContent title={t('wards')} titleIcon={<MeetingRoom style={{ color: 'white' }} />}>
      <form autoCorrect="on" autoComplete="off" onSubmit={submitHandle}>
        <TextField
          data-e2e={e2eprefix + 'fld-name'}
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="text"
          name="name"
          value={row?.name}
          {
            ...{
              /*onChange={handleChange}*/
            }
          }
          label={t('name')}
          id="outlined-multiline-flexible"
          variant="outlined"
        />

        <TextField
          data-e2e={e2eprefix + 'fld-description'}
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="text"
          name="description"
          value={row?.description}
          {
            ...{
              /*onChange={handleChange}*/
            }
          }
          label={t('desc')}
          id="outlined-start-adornment"
          variant="outlined"
        />
        <DeviceTransferList />
      </form>
    </CommonContent>
  )
}
