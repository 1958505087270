import { Route } from 'react-router-dom'
import RoomsView from './rooms/RoomsView'
import React from 'react'
import RoomDetail from './rooms/RoomDetail'
import RcusView from './rcus/RcusView'
import RcuDetail from './rcus/RcusDetail'
import HipUserView from './hipUsers/HipUsersView'
import HipUserDetail from './hipUsers/HipUserDetail'

const HelpIPRoutes = (
  <React.Fragment>
    <Route path="rooms" element={<RoomsView />} />
    <Route path="rooms/:id" element={<RoomDetail />} />
    <Route path="rcus" element={<RcusView />} />
    <Route path="rcus/:id" element={<RcuDetail />} />
    <Route path="nurses" element={<HipUserView userType="nurses" />} />
    <Route path="nurses/:id" element={<HipUserDetail userType="nurses" />} />
    <Route path="maintenance" element={<HipUserView userType="maintenance" />} />
    <Route path="maintenance/:id" element={<HipUserDetail userType="maintenance" />} />
  </React.Fragment>
)

export default HelpIPRoutes
