import axios from 'axios'
import { useEffect } from 'react'
import { SSEProvider, useSSE } from 'react-hooks-sse'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material'
import { getAPIService, getSSEService } from '../getServerHost'
import { groupsRequest } from '../common/store/actions/groups/actions'
import { userProfileRequest } from '../common/store/actions/profile/actions'
import {
  deviceCleanErrorRequest,
  deviceCleanSuccessRequest,
  devicesRequest,
} from '../common/store/actions/devices/actions'
import { getUserProfileState } from '../common/store/selectors/profiles'
import { getUserInfo } from '../common/store/selectors/user'
import { devicesState } from '../common/store/selectors/devices'
import AdminButton from './components/AdminButton'
import AllStopButton from './components/AllStopButton'
import ZoneIndicator from './components/ZoneIndicator'
import MqttPublishButton from './components/MqttPublishButton'
import MusicPlayerBox from './components/MusicPlayerBox'
import ResetSystemButton from './components/ResetSystemButton'
import SelectZone from './components/SelectZone'
import MicrophoneButton from './components/MicrophoneButton'
import { IWelcome, IpDevice } from '../types'
import { OutputDevices } from './components/OutputDevices'
import { InputDevices } from './components/InputDevices'
import Clock from '../components/Clock'
import ExitButton from './components/ExitButton'
import CircularProgress from '@mui/material/CircularProgress'

const Welcome = () => {
  const welcome = useSSE<IWelcome>('welcome', { events: [] })
  useEffect(() => {
    // Fire devices event
    welcome.events.forEach((event) => axios.get(`${getAPIService}/event/${event}`))
  }, [welcome])
  return <></>
}

const showMusicChannel = process.env?.REACT_APP_PAGING_MODE !== 'MQTT'

interface IProps {
  isMobileMode?: boolean
}

const Dashboard2 = ({ isMobileMode }: IProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const userInfoState = useSelector(getUserInfo)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading } = useSelector(getUserProfileState)
  const { devices, isLoading_dev, success, error } = useSelector(devicesState)
  const speakers = devices.items as IpDevice[] // VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
  const isDesktopMode = !isMobileMode
  /*
  const isAdmin =
    profiles &&
    profiles.some((profiles) => profiles.privileges.some((privilege) => privilege === 'ALL'))
  */

  useEffect(() => {
    dispatch(userProfileRequest({ user_id: userInfoState.id.toString() }))
    const req = {
      pageSet: { limit: '200', page: '1' },
      type: 'speaker',
    }
    dispatch(devicesRequest(req))
    dispatch(groupsRequest())
  }, [])

  //TODO sacar en una función y reutilizar en todos los componentes que usan snackbar
  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(deviceCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(deviceCleanErrorRequest())
    }
  }, [success, error])

  return (
    <>
      {!isLoading && (
        <SSEProvider endpoint={`${getSSEService}/events?user_id=${userInfoState.id}`}>
          <CssBaseline />
          <Container maxWidth="xl">
            <Welcome />
            {isMobileMode ? (
              <>
                <Box mt={1} mb={50}>
                  <Box display="flex">
                    <ExitButton />
                    <AllStopButton />
                    <ResetSystemButton />
                    <Box>
                      <SelectZone />
                    </Box>
                  </Box>
                  <Box height={24}>
                    <ZoneIndicator />
                  </Box>
                  <Box>
                    <OutputDevices isMobileMode speakers={speakers} />
                  </Box>
                  {showMusicChannel && (
                    <MusicPlayerBox type="music-files" title="Music channel" data-e2e="music-box" />
                  )}
                  <MusicPlayerBox
                    key="messages"
                    type="msg-files"
                    title="Messages"
                    data-e2e="messages-box"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                  <Box m={2} display="flex" flexWrap="wrap">
                    {isDesktopMode && (
                      <Box m={2}>
                        <AdminButton />
                      </Box>
                    )}
                    <Box m={2}>
                      <AllStopButton />
                    </Box>
                    {isDesktopMode && (
                      <>
                        <MicrophoneButton />
                        <InputDevices />
                      </>
                    )}
                    <Box>
                      <SelectZone />
                    </Box>
                    <Box minWidth={300} height={170}>
                      <MusicPlayerBox type="msg-files" title="Messages" data-e2e="messages-box" />
                    </Box>
                  </Box>
                  <Box m={4} display="flex" flexWrap="wrap">
                    <Clock />
                  </Box>
                  <Box m={4} display="flex" flexWrap="wrap">
                    <MqttPublishButton />
                    <ResetSystemButton />
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box height={24}>
                      <ZoneIndicator />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {isLoading_dev ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        margin="25px"
                        flexDirection="column"
                      >
                        <CircularProgress size={80} />
                        <Typography variant="h6" color="text.secondary" align="center" p={20}>
                          {t('loading_devices')}
                        </Typography>
                      </Box>
                    ) : devices.items.length === 0 ? (
                      <Typography variant="h6" color="text.secondary" align="center" p={20}>
                        {t('no_items')}
                      </Typography>
                    ) : (
                      <Box border={'1px solid grey'} borderRadius="5px">
                        <OutputDevices speakers={speakers} />
                      </Box>
                    )}
                  </Grid>
                  {showMusicChannel && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Box width={500}>
                          <MusicPlayerBox
                            type="music-files"
                            title="Music channel"
                            data-e2e="music-box"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Container>
        </SSEProvider>
      )}
    </>
  )
}

export default Dashboard2
