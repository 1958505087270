import {
  DEVICES_REQUEST,
  DEVICES_TYPES_REQUEST,
  DEVICE_CREATE_REQUEST,
  DEVICE_DELETE_REQUEST,
  DEVICE_DETAIL_REQUEST,
  DEVICE_UPDATE_REQUEST,
  IPDEVICES_REQUEST,
  VOLUME_UPDATE_REQUEST,
} from '../actions/devices/actionTypes'
import { MESSAGES_REQUEST } from '../actions/messages/actionTypes'
import {
  GROUPS_REQUEST,
  GROUP_CREATE_REQUEST,
  GROUP_DELETE_REQUEST,
  GROUP_REQUEST,
  GROUP_UPDATE_REQUEST,
} from '../actions/groups/actionTypes'
import { LICENSE_REQUEST, UPDATE_LICENSE_REQUEST } from '../actions/license/actionTypes'
import { MQTT_REQUEST } from '../actions/mqtt/actionTypes'
import { USER_PROFILE_REQUEST } from '../actions/profile/actionTypes'
import {
  USER_INFO_REQUEST,
  USER_NOTIFICATION_TOKEN_REQUEST,
  USER_PUSH_SUBSCRIPTION_REQUEST,
} from '../actions/user/actionTypes'
import { WARDS_REQUEST } from '../../../hip/actions/wards/actionTypes'
import {
  AVAILABlE_RCUS_REQUEST,
  RCUS_REQUEST,
  RCU_CREATE_REQUEST,
  RCU_DELETE_REQUEST,
  RCU_DETAIL_REQUEST,
  RCU_UPDATE_REQUEST,
} from '../../../hip/actions/rcus/actionTypes'
import {
  ROOMS_REQUEST,
  ROOM_CREATE_REQUEST,
  ROOM_DELETE_REQUEST,
  ROOM_DETAIL_REQUEST,
  ROOM_UPDATE_REQUEST,
} from '../../../hip/actions/rooms/actionTypes'
import {
  KEY_CREATE_REQUEST,
  KEY_UPDATE_REQUEST,
  KEY_DELETE_REQUEST,
  HIP_USERS_KEYS_REQUEST,
  HIP_USER_CREATE_REQUEST,
  HIP_USER_DETAIL_REQUEST,
  HIP_USER_UPDATE_REQUEST,
  HIP_USER_DELETE_REQUEST,
} from '../../../hip/actions/hipUsers/actionTypes'
import {
  EXCEPTIONS_CREATE_REQUEST,
  EXCEPTION_REQUEST,
  TASKS_REQUEST,
  TASK_CREATE_REQUEST,
  TASK_DELETE_REQUEST,
  TASK_DETAIL_REQUEST,
  TASK_UPDATE_REQUEST,
} from '../actions/tasks/actionTypes'
import {
  MSG_FILES_REQUEST,
  MUSIC_FILES_REQUEST,
  FILE_UPLOAD_REQUEST,
  FILE_DELETE_REQUEST,
  PLAY_MSG_FILE_REQUEST,
  PLAY_MUSIC_FILE_REQUEST,
  STOP_FILE_REQUEST,
  RADIO_REQUEST,
  RADIO_DELETE_REQUEST,
  RADIO_CREATE_REQUEST,
} from '../actions/files/actionTypes'
import { sagaGetMessages } from './messagesGetter'
import { sagaGetGroups } from './groupsGetter'
import { sagaDeleteGroup } from './groupDelete'
import { sagaGetDevices } from './devices/devicesGetter'
import { sagaGetIPDevices } from './devices/ipdevices'
import { sagaVolumeUpdate } from './devices/volumeUpdate'
import { sagaDeviceUpdate } from './devices/deviceUpdate'
import { sagaGetLicense, sagaUpdateLicense } from './license'
import { sagaMqttPublish } from './mqtt'
import { sagaUserProfile } from './profile'
import { takeLatest } from '@redux-saga/core/effects'
import { userInfo, userNotificationTokenRequest, userPushSubscriptionRequest } from './user'
import { sagaGetWards } from '../../../hip/store/sagas/wards/wards'
import { sagaGetRcus } from '../../../hip/store/sagas/rcus/rcusGetter'
import { sagaGetRooms } from '../../../hip/store/sagas/rooms/roomsGetter'
import { sagaRcuCreate } from '../../../hip/store/sagas/rcus/rcuCreate'
import { sagaRcuDelete } from '../../../hip/store/sagas/rcus/rcuDelete'
import { sagaRoomCreate } from '../../../hip/store/sagas/rooms/roomCreate'
import { sagaRoomDelete } from '../../../hip/store/sagas/rooms/roomDelete'
import { sagaRcuUpdate } from '../../../hip/store/sagas/rcus/rcuUpdate'
import { sagaGetRcuDetail } from '../../../hip/store/sagas/rcus/rcuDetail'
import { sagaGetAvailableRcus } from '../../../hip/store/sagas/rcus/rcusAvailable'
import { sagaGetRoomDetail } from '../../../hip/store/sagas/rooms/roomDetail'
import { sagaRoomUpdate } from '../../../hip/store/sagas/rooms/roomUpdate'
import { sagaGetUserAndKey } from '../../../hip/store/sagas/hipUsers/hipUserGetter'
import { sagaKeyCreate } from '../../../hip/store/sagas/hipUsers/keyCreate'
import { sagaKeyUpdate } from '../../../hip/store/sagas/hipUsers/keyUpdate'
import { sagaUserCreate } from '../../../hip/store/sagas/hipUsers/userCreate'
import { sagaKeyDelete } from '../../../hip/store/sagas/hipUsers/keyDelete'
import { sagaUserDetail } from '../../../hip/store/sagas/hipUsers/hipUserDetail'
import { sagaUserDelete } from '../../../hip/store/sagas/hipUsers/hipUserDelete'
import { sagaNurseUpdate } from '../../../hip/store/sagas/hipUsers/hipUserUpdate'
import { sagaGetMsgFiles } from './files/getMsgFilesSaga'
import { sagaGetMusicFiles } from './files/getMusicFilesSaga'
import { sagaUploadFile } from './files/uploadFileSaga'
import { sagaDeleteFile } from './files/deleteFile'
import { sagaPlayMsgFile } from './files/playMsgFile'
import { sagaPlayMusicFile } from './files/playMusicFile'
import { sagaStopFile } from './files/stopFile'
import { sagaGetRadios } from './files/getRadios'
import { sagaRadioCreate } from './files/createRadio'
import { sagaDeleteRadio } from './files/deleteRadio'
import { sagaGetTasks } from './tasksGetter'
import { sagaGetTask } from './taskDetail'
import { sagaTaskCreate } from './taskCreate'
import { sagaTaskUpdate } from './taskUpdate'
import { sagaTaskDelete } from './taskDelete'
import { sagaGetExceptions } from './exceptionsGetter'
import { sagaCreateExceptions } from './exceptionsCreate'
import { sagaDeviceCreate } from './devices/deviceCreate'
import { sagaGetDeviceTypes } from './devices/deviceTypesGetter'
import { sagaGetDevice } from './devices/deviceGetter'
import { sagaDeleteDevice } from './devices/deviceDelete'
import { sagaCreateGroup } from './groupCreate'
import { sagaUpdateGroup } from './groupUpdate'
import { sagaGetGroup } from './groupGetter'

function* rootSaga() {
  yield takeLatest(MESSAGES_REQUEST, sagaGetMessages)
  yield takeLatest(GROUPS_REQUEST, sagaGetGroups)
  yield takeLatest(GROUP_REQUEST, sagaGetGroup)
  yield takeLatest(GROUP_CREATE_REQUEST, sagaCreateGroup)
  yield takeLatest(GROUP_UPDATE_REQUEST, sagaUpdateGroup)
  yield takeLatest(GROUP_DELETE_REQUEST, sagaDeleteGroup)
  yield takeLatest(USER_INFO_REQUEST, userInfo)
  yield takeLatest(DEVICES_TYPES_REQUEST, sagaGetDeviceTypes)
  yield takeLatest(DEVICES_REQUEST, sagaGetDevices)
  yield takeLatest(DEVICE_DETAIL_REQUEST, sagaGetDevice)
  yield takeLatest(DEVICE_CREATE_REQUEST, sagaDeviceCreate)
  yield takeLatest(DEVICE_DELETE_REQUEST, sagaDeleteDevice)
  yield takeLatest(IPDEVICES_REQUEST, sagaGetIPDevices)
  yield takeLatest(VOLUME_UPDATE_REQUEST, sagaVolumeUpdate)
  yield takeLatest(DEVICE_UPDATE_REQUEST, sagaDeviceUpdate)
  yield takeLatest(MQTT_REQUEST, sagaMqttPublish)
  yield takeLatest(LICENSE_REQUEST, sagaGetLicense)
  yield takeLatest(UPDATE_LICENSE_REQUEST, sagaUpdateLicense)
  yield takeLatest(USER_PROFILE_REQUEST, sagaUserProfile)
  yield takeLatest(USER_NOTIFICATION_TOKEN_REQUEST, userNotificationTokenRequest)
  yield takeLatest(USER_PUSH_SUBSCRIPTION_REQUEST, userPushSubscriptionRequest)
  yield takeLatest(MSG_FILES_REQUEST, sagaGetMsgFiles)
  yield takeLatest(MUSIC_FILES_REQUEST, sagaGetMusicFiles)
  yield takeLatest(FILE_UPLOAD_REQUEST, sagaUploadFile)
  yield takeLatest(FILE_DELETE_REQUEST, sagaDeleteFile)
  yield takeLatest(PLAY_MSG_FILE_REQUEST, sagaPlayMsgFile)
  yield takeLatest(PLAY_MUSIC_FILE_REQUEST, sagaPlayMusicFile)
  yield takeLatest(STOP_FILE_REQUEST, sagaStopFile)
  yield takeLatest(RADIO_REQUEST, sagaGetRadios)
  yield takeLatest(RADIO_CREATE_REQUEST, sagaRadioCreate)
  yield takeLatest(RADIO_DELETE_REQUEST, sagaDeleteRadio)
  yield takeLatest(WARDS_REQUEST, sagaGetWards)
  yield takeLatest(RCUS_REQUEST, sagaGetRcus)
  yield takeLatest(RCU_DETAIL_REQUEST, sagaGetRcuDetail)
  yield takeLatest(RCU_CREATE_REQUEST, sagaRcuCreate)
  yield takeLatest(RCU_DELETE_REQUEST, sagaRcuDelete)
  yield takeLatest(RCU_UPDATE_REQUEST, sagaRcuUpdate)
  yield takeLatest(AVAILABlE_RCUS_REQUEST, sagaGetAvailableRcus)
  yield takeLatest(ROOMS_REQUEST, sagaGetRooms)
  yield takeLatest(ROOM_CREATE_REQUEST, sagaRoomCreate)
  yield takeLatest(ROOM_DELETE_REQUEST, sagaRoomDelete)
  yield takeLatest(ROOM_DETAIL_REQUEST, sagaGetRoomDetail)
  yield takeLatest(ROOM_UPDATE_REQUEST, sagaRoomUpdate)
  yield takeLatest(KEY_CREATE_REQUEST, sagaKeyCreate)
  yield takeLatest(KEY_UPDATE_REQUEST, sagaKeyUpdate)
  yield takeLatest(KEY_DELETE_REQUEST, sagaKeyDelete)
  yield takeLatest(HIP_USERS_KEYS_REQUEST, sagaGetUserAndKey)
  yield takeLatest(HIP_USER_CREATE_REQUEST, sagaUserCreate)
  yield takeLatest(HIP_USER_DETAIL_REQUEST, sagaUserDetail)
  yield takeLatest(HIP_USER_DELETE_REQUEST, sagaUserDelete)
  yield takeLatest(HIP_USER_UPDATE_REQUEST, sagaNurseUpdate)
  yield takeLatest(TASKS_REQUEST, sagaGetTasks)
  yield takeLatest(TASK_DETAIL_REQUEST, sagaGetTask)
  yield takeLatest(TASK_CREATE_REQUEST, sagaTaskCreate)
  yield takeLatest(TASK_UPDATE_REQUEST, sagaTaskUpdate)
  yield takeLatest(TASK_DELETE_REQUEST, sagaTaskDelete)
  yield takeLatest(EXCEPTION_REQUEST, sagaGetExceptions)
  yield takeLatest(EXCEPTIONS_CREATE_REQUEST, sagaCreateExceptions)
}

export type Action<T> = {
  payload: T
  type: string
}

export default rootSaga
