import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, TextField, Grid } from '@mui/material'
import { Done, Delete, Edit } from '@mui/icons-material'
import axios from 'axios'
import { CommonContent } from '../components/CommonContent'
import { useSnackbar } from 'notistack'
import { PasswordField } from './passwordField'
import { useTranslation } from 'react-i18next'
import TransferProfile from '../components/TransferProfile'
import { topicGenerator, multicast_acrt, ipMulticast } from '../Utils'
import { getAPIService } from '../getServerHost'

const isPaging = process.env?.REACT_APP_PRODUCT_NAME == 'PAGING'
const showMulticast = process.env?.REACT_APP_PAGING_MODE !== 'MQTT' //
const showMQTT = process.env?.REACT_APP_PAGING_MODE !== 'MULTICAST' // Even if MQTT, empty for default

interface IUser {
  username: string
  password: string
  name: string
  active: boolean
  checkedA: boolean
  multicast_ip: string
  multicast_port: number
  mqtt_topic: string
}

interface IProfiles {
  id: number
  name: string
  editable: number
}

interface IProfResponce {
  profile_id: number
  privileges: string[]
}

interface IUserProf {
  id: number
  profiles: IProfResponce[]
}

export const UserForm = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()
  const [addUser, setAddUser] = React.useState(false)
  const [profilesList, setProfiles] = React.useState<IProfiles[]>()
  const [resultList, setResult] = React.useState<string[]>([])
  const [transferList, setTransferList] = React.useState(<></>)
  const navigate = useNavigate()
  const [usernameErrorMessage, setUserErrorMessage] = React.useState<string | undefined>()

  const [user, setUser] = React.useState({
    username: '',
    password: '',
    name: '',
    active: true,
    checkedA: true,
    multicast_ip: `224.0.0.${ipMulticast}`,
    multicast_port: multicast_acrt,
    mqtt_topic: topicGenerator(),
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name)
    if (event.target.name === 'username') {
      const re = /^[a-zA-Z0-9_-]*$/
      if (event.target.value === '' || !re.test(event.target.value)) {
        setUserErrorMessage(t('not_valid_name'))
      } else {
        setUserErrorMessage(undefined)
      }
    }
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const prof = await axios.get<IProfiles[]>(`${getAPIService}/profile`)
      let profiles = prof.data
      if (isPaging) {
        profiles = profiles.filter((profile) => ![2, 3, 4].includes(profile.id))
      }
      setProfiles(profiles)
      const list: string[] = []
      profiles.map((item: IProfiles) => {
        list.push(item.name)
      })

      if (profiles) {
        if (params.id === 'add') {
          setTransferList(<TransferProfile profiles={list} selected={[]} setSelected={setResult} />)
        }
        setAddUser(true)
        return
      } else {
        const selected: string[] = []
        try {
          const profUser = await axios.get<IUserProf>(`${getAPIService}/user_profile/` + params.id)
          if (profUser?.data) {
            profUser.data.profiles.map((item) => {
              profiles?.forEach(function (value) {
                if (value.id == item.profile_id) {
                  selected.push(value.name)
                  const index: number = list.indexOf(value.name)
                  list.splice(index, 1)
                }
              })
            })
          }
        } catch (e) {
          console.log(e)
        }
        setTransferList(
          <TransferProfile profiles={list} selected={selected} setSelected={setResult} />,
        )
        const result = await axios.get<IUser>(`${getAPIService}/users/` + params.id)
        if (result?.data) {
          setUser(result?.data)
        }
      }
    }
    fetchData().catch(console.error)
  }, [resultList])

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (addUser) {
      axios
        .post(`${getAPIService}/users`, {
          username: user?.username,
          password: user?.password,
          name: user?.name,
          active: true, //user?.active,
          multicast_ip: user?.multicast_ip,
          multicast_port: user?.multicast_port,
          mqtt_topic: user?.mqtt_topic,
        })
        .then((response) => {
          const resultIds: number[] = []
          resultList.map((item) => {
            profilesList.forEach(function (value) {
              if (value.name == item) resultIds.push(value.id)
            })
          })
          axios.post(`${getAPIService}/user_profile`, {
            user_id: response.data.id,
            profiles: resultIds,
          })
          enqueueSnackbar('Add success!!', { variant: 'success' })
          navigate('/users')
        })
    } else {
      const fetchData = async () => {
        const resultIds: number[] = []
        resultList.map((item) => {
          profilesList.forEach(function (value) {
            if (value.name == item) resultIds.push(value.id)
          })
        })
        await axios.post(`${getAPIService}/user_profile`, {
          user_id: params.id,
          profiles: resultIds,
        })

        await axios
          .patch(`${getAPIService}/users/` + params.id, {
            username: user?.username,
            password: user?.password,
            name: user?.name,
            active: true, //user?.active,
            multicast_ip: user?.multicast_ip,
            multicast_port: user?.multicast_port,
            mqtt_topic: user?.mqtt_topic,
          })
          .then(() => {
            enqueueSnackbar('Save success!!', { variant: 'success' })
            navigate('/users')
          })
      }
      fetchData().catch(console.error)
    }
  }

  return (
    <CommonContent title={t('user')} titleIcon={<Edit style={{ color: 'white' }} />}>
      <form autoCorrect="on" autoComplete="off" onSubmit={submitHandle}>
        <TextField
          autoComplete="off"
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="text"
          name="username"
          value={user?.username}
          onChange={handleChange}
          label={t('username')}
          id="outlined-multiline-flexible"
          variant="outlined"
          data-e2e="users-add-fld-username"
          error={usernameErrorMessage !== undefined}
          helperText={usernameErrorMessage}
          disabled={!addUser}
        />
        <PasswordField
          adduser={addUser}
          password={user?.password}
          handleChange={handleChange}
          data-e2e="users-add-fld-password"
        />
        <TextField
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="text"
          name="name"
          value={user?.name}
          onChange={handleChange}
          label={t('name')}
          id="outlined-start-adornment"
          variant="outlined"
          data-e2e="users-add-fld-name"
        />
        {showMulticast ? (
          <>
            <TextField
              style={{ paddingBottom: 20 }}
              fullWidth
              required
              type="text"
              name="multicast_ip"
              value={user?.multicast_ip}
              onChange={handleChange}
              label={t('multicast_ip')}
              InputLabelProps={{ shrink: true }}
              id="outlined-start-adornment"
              variant="outlined"
              data-e2e="users-add-fld-mcast-ip"
            />

            <TextField
              style={{ paddingBottom: 20 }}
              fullWidth
              required
              type="number"
              name="multicast_port"
              value={user?.multicast_port}
              onChange={handleChange}
              label={t('multicast_port')}
              InputLabelProps={{ shrink: true }}
              id="outlined-start-adornment"
              variant="outlined"
              data-e2e="users-add-fld-mcast-port"
            />
          </>
        ) : (
          <></>
        )}
        {showMQTT ? (
          <TextField
            style={{ paddingBottom: 20 }}
            fullWidth
            required
            type="text"
            name="mqtt_topic"
            value={user?.mqtt_topic}
            onChange={handleChange}
            label={t('mqtt_topic')}
            id="outlined-start-adornment"
            variant="outlined"
            data-e2e="users-add-fld-mqtt-topic"
          />
        ) : (
          <></>
        )}
        {transferList}
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                navigate('/users')
              }}
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
              data-e2e="users-add-btn-cancel"
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={usernameErrorMessage !== undefined}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<Done />}
              data-e2e="users-add-btn-add"
            >
              {addUser ? t('add') : t('save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CommonContent>
  )
}
