import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Grid, TextField } from '@mui/material'
import { AddCircle, Done, SettingsRemote } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import { rcuCreateRequest, rcuUpdateRequest } from '../actions/rcus/actions'
import { Rcu, RcuFormValues } from '../types'

interface RcusFormProps {
  initialValues?: Rcu
}

const RcusForm = ({ initialValues }: RcusFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleSubmit, reset, control, getValues } = useForm<RcuFormValues>()

  const isEdit = !!initialValues

  const onSubmit = handleSubmit((data: RcuFormValues) => {
    if (isEdit) {
      const updatedData: Rcu = {
        id: initialValues.id,
        device_id: initialValues.device_id,
        description: data.description,
      }
      dispatch(rcuUpdateRequest(updatedData))
    } else {
      if (data) dispatch(rcuCreateRequest(data))
    }
    reset({
      device_id: '',
      description: '',
    })
  })

  return (
    <CommonContent
      title={isEdit ? t('edit_device') : t('add_device')}
      titleIcon={<SettingsRemote style={{ color: 'white' }} />}
      data-e2e={isEdit ? 'rcus-title-edit' : 'rcus-title-add'}
    >
      <form>
        <Grid container direction="row" alignItems="center" spacing={3} m={6}>
          <Grid item xs={5}>
            <Controller
              name="device_id"
              control={control}
              defaultValue={isEdit ? initialValues.device_id : ''}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const device = getValues('device_id')
                  const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/
                  if (!regex.test(device)) return t('not_mac')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="device_id-fld"
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={t('device_id')}
                  variant="outlined"
                  disabled={isEdit}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name="description"
              control={control}
              defaultValue={isEdit ? initialValues.description : ''}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const name = getValues('description')
                  if (name.trim() !== name) return t('check_empty')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="rcus-fld-desc"
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEdit ? t('update_desc') : t('description')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            data-e2e={isEdit ? 'rcu-btn-save' : 'rcu-btn-add'}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            startIcon={<AddCircle />}
          >
            {isEdit ? t('save') : t('add')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-e2e="rcu-btn-done"
            variant="contained"
            color="success"
            onClick={isEdit ? () => navigate('/rcus') : () => navigate('/rooms')}
            startIcon={<Done />}
          >
            {t('done')}
          </Button>
        </Grid>
      </Grid>
    </CommonContent>
  )
}

export default RcusForm
