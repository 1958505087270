import { call, put } from '@redux-saga/core/effects'
import { Action } from './root'
import axios from 'axios'
import { PaginatedResponse } from '../../../types'
import { tasksSuccess, tasksError } from '../actions/tasks/actions'
import { PageSet } from '../../../hip/types'
import { getTasks } from '../../api/tasks/tasksGetter'

export function* sagaGetTasks({ payload }: Action<{ pageSet: PageSet; type: string }>) {
  console.log('pasé por get tasks', payload.pageSet.page)
  try {
    const response: PaginatedResponse = yield call(getTasks, payload)
    yield put(tasksSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(tasksError(error.response.data as unknown))
    } else {
      yield put(tasksError(error))
      throw error
    }
  }
}
