import axios from 'axios'
import { getAPIService } from '../../getServerHost'
import { UpdatedRoomdData } from '../types'

export const roomUpdate = async (payload: UpdatedRoomdData) => {
  const roomId = payload.id
  const response = await axios.patch(`${getAPIService}/v2/api/rooms/${roomId}`, {
    name: payload.name,
    rcus: payload.rcus,
  })
  return response.data
}
