import axios from 'axios'
import { getAPIService } from '../../../getServerHost'
import { IMqttRequest } from '../types'

export const mqttPublish = async (data: IMqttRequest) => {
  const { topic, payload } = data
  const response = await axios.post(`${getAPIService}/actions/mqtt`, {
    topic: topic,
    payload: payload,
  })
  return response.data
}
