import { RcuEvent, ButtonAtr, MessageNote, HipIconType } from '../types'

export const oldEvent = (
  event: string,
  feedbackStack: Array<RcuEvent[]>,
  currentFeedback: RcuEvent[],
) => {
  let prevId = null
  let currentId = null

  const olderFeedback = feedbackStack[0] ? feedbackStack[1] : null
  const prevFeedback = feedbackStack[1] ? feedbackStack[1] : null

  if (prevFeedback) {
    for (let i = 0; i < prevFeedback.length; i++) {
      if (prevFeedback[i].feedback.new === event) {
        prevId = prevFeedback[i].id
        break
      }
    }

    for (let i = 0; i < currentFeedback.length; i++) {
      if (currentFeedback[i].feedback.new === event) {
        currentId = currentFeedback[i].id
        break
      }
    }

    if (prevId !== currentId) {
      return false
    } else {
      for (let i = 0; i < olderFeedback.length; i++) {
        if (olderFeedback[i].feedback.new === event) {
          prevId = olderFeedback[i].id
          break
        }
      }
      if (prevId !== currentId) {
        return false
      } else return true
    }
  } else if (olderFeedback) {
    for (let i = 0; i < olderFeedback.length; i++) {
      if (olderFeedback[i].feedback.new === event) {
        prevId = olderFeedback[i].id
        break
      }
    }
    if (prevId !== currentId) {
      return false
    } else return true
  } else return false
}

export const getButtonAttributesAndMessage = (event: RcuEvent | null) => {
  const result: ButtonAtr = {
    image: 'ERROR',
    // color: 'inherit',
    fill: '#354652',
  }

  const messageResult: MessageNote = {
    message: null,
  }

  type AttributeMapping = {
    [key: string]: {
      fill: string
      image: HipIconType
      message?: string
    }
  }

  const attributeMapping: AttributeMapping = {
    online: { fill: '#979797', image: 'OK' },
    offline: { fill: '#354652', image: 'ERROR' },
    BedCl: { fill: '#f9b03d', image: 'BED_CALL', message: 'Bed call' },
    BedNA: { fill: '#5c2582', image: 'IGNORED', message: 'Bed call unattended' },
    BathCl: { fill: '#f9b03d', image: 'BATHROOM_CALL', message: 'Bathroom call' },
    BathNA: { fill: '#5c2582', image: 'BATHROOM_IGNORED', message: 'Bathroom call unattended' },
    CBlue: { fill: '#df007c', image: 'CODE_BLUE', message: 'CODE BLUE' },
    CBlNA: { fill: '#5c2582', image: 'CODE_BLUE', message: 'CODE BLUE UNATTENDED' },
    ClIn: { fill: '#f05a25', image: 'MESSAGE_SENT', message: 'Calling room' },
    ClEst: { fill: '#f05a25', image: 'SPOKEN', message: 'Call established' },
    NBt: { fill: '#43aaf3', image: 'NURSE', message: 'Nurse at room' },
    NCard: { fill: '#43aaf3', image: 'NURSE', message: 'Nurse at room' },
    MnCard: { fill: '#43aaf3', image: 'MAINTENANCE', message: 'Room under maintenance' },
    EoS: { fill: '#979797', image: 'OK', message: 'End of service' },
  }

  const rcuNew = event.feedback?.new
  if (rcuNew && attributeMapping[rcuNew]) {
    const { fill, image, message } = attributeMapping[rcuNew]
    result.fill = fill
    result.image = image
    messageResult.message = message
  }

  return { attributes: result, message: messageResult }
}
