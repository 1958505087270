import { useState } from 'react'
import { Box, Fab, Modal, Typography } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import HipButton from '../components/HipButton'
import { HipIconType } from '../types'

const styles = {
  floatingButton: {
    margin: 4,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatingDiv: {
    zIndex: 100,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'red',
    top: 0,
    left: 0,
    display: 'flex',
  },
}

const info = [
  { fill: '#979797', image: 'OK', name: 'Active room without events' },
  { fill: '#354652', image: 'ERROR', name: 'Inactive room' },
  { fill: '#f9b03d', image: 'BED_CALL', name: 'Bed call' },
  { fill: '#5c2582', image: 'IGNORED', name: 'Bed call ignored' },
  { fill: '#f9b03d', image: 'BATHROOM_CALL', name: 'Bathroom call' },
  { fill: '#5c2582', image: 'BATHROOM_IGNORED', name: 'Bathtoom call ignored' },
  { fill: '#df007c', image: 'CODE_BLUE', name: 'CODE BLUE' },
  { fill: '#5c2582', image: 'CODE_BLUE', name: 'CODE BLUE IGNORED' },
  { fill: '#f05a25', image: 'MESSAGE_SENT', name: 'Calling room' },
  { fill: '#f05a25', image: 'SPOKEN', name: 'Call established' },
  { fill: '#43aaf3', image: 'NURSE', name: 'Nurse at room' },
  { fill: '#43aaf3', image: 'MAINTENANCE', name: 'Room under maintenance' },
]

const IconsInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Fab sx={styles.floatingButton}>
        <HelpIcon color="primary" onClick={handleOpenModal} />
      </Fab>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '15px',
            width: '1200px',
            maxWidth: '90%',
          }}
        >
          <Box display={'flex'} flexDirection={'row'} mx={10} width="1200px" maxWidth="90%">
            {info.map((button, index) => (
              <div
                key={index}
                style={{
                  margin: '10px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <HipButton
                  variant="contained"
                  viewMode="large-icon"
                  image={button.image as HipIconType}
                  fill={button.fill}
                  // color="inherit"
                />
                <Typography variant="caption">{button.name} </Typography>
              </div>
            ))}
          </Box>
        </div>
      </Modal>
    </>
  )
}

export default IconsInfo
