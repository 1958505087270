import axios from 'axios'
import { getAPIService } from '../../../getServerHost'
import { PageSet } from '../../../hip/types'

export const getTasks = async (payload: { pageSet: PageSet; type: string }) => {
  const { limit, page } = payload.pageSet
  const response = await axios.get(
    `${getAPIService}/v2/api/cron-tasks/${payload.type}?limit=${limit}&page=${page}`,
  )
  return response.data
}
