import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { blue } from '@mui/material/colors'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
  Box,
} from '@mui/material'
import { PeopleAlt, PersonAdd, Delete } from '@mui/icons-material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import { CommonContent } from '../components/CommonContent'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { getAPIService } from '../getServerHost'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    minWidth: 650,
  },

  root: {
    maxWidth: 'sm',
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'center',
    //color: theme.palette.text.secondary,
  },

  expandOpen: {
    transform: 'rotate(180deg)',
  },
  head: {
    backgroundColor: blue[500],
  },
  button: {
    margin: theme.spacing(1),
  },
  click_cancelar: {
    '&:hover': {
      backgroundColor: '#3251AD',
      color: 'white',
    },
  },
  click_delete: {
    '&:hover': {
      backgroundColor: '#FF6466',
      color: 'white',
    },
  },
}))

interface IUser {
  id: number
  username: string
  password: string
  name: string
  active: boolean
}

export default function Users() {
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { classes } = useStyles()

  const [rows, setRows] = React.useState<IUser[]>([])

  const [open, setOpen] = React.useState(false)
  const [userToDelete, setUserToDelete] = React.useState<Partial<IUser>>({})

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    axios
      .get<IUser[]>(`${getAPIService}/users`)
      .then(({ data }) => setRows(data))
      .catch(console.error)
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>, user: IUser) => {
    setUserToDelete(user)
    setOpen(true)
  }

  const handleConfirmDelete = () => {
    axios.delete(`${getAPIService}/users/` + userToDelete.id).then(() => {
      enqueueSnackbar('Delete success!!', { variant: 'success' })
      axios.get<IUser[]>(`${getAPIService}/users`).then(({ data }) => setRows(data))
      setOpen(false)
    })
  }

  return (
    <CommonContent title={t('user_title')} titleIcon={<PeopleAlt style={{ color: 'white' }} />}>
      {hasPrivilege(Privileges.ADD_USERS) && (
        <Box mb={4}>
          <Button
            data-e2e="users-btn-add"
            component={Link}
            to="/users/add"
            variant="contained"
            color="success"
            startIcon={<PersonAdd />}
          >
            {t('user_add')}
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Divider />
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ textAlign: 'center' }}>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">{t('username')}</TableCell>
              <TableCell align="center">{t('name')}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} data-e2e={'users-row-' + String(row.id)}>
                <TableCell component="th" scope="row" align="center" data-e2e={'users-row-cell-id'}>
                  {row.id}
                </TableCell>
                <TableCell align="center" data-e2e={'users-row-cell-username'}>
                  {row.username}
                </TableCell>
                <TableCell align="center" data-e2e={'users-row-cell-name'}>
                  {row.name}
                </TableCell>
                {/* <TableCell align="center" data-e2e={'users-row-cell-actions'}>
                  <Grid>
                    {hasPrivilege(Privileges.EDIT_USERS) && row.username !== 'admin' && (
                      <Button
                        component={Link}
                        to={'/users/' + row.id}
                        data-e2e={'users-row-cell-actions-edit'}
                        color="primary"
                      >
                        <Edit />
                      </Button>
                    )}
                  </Grid>
                </TableCell> */}
                <TableCell align="center">
                  <Grid>
                    {hasPrivilege(Privileges.DELETE_USERS) && row.username !== 'admin' && (
                      <Button
                        variant="text"
                        onClick={(e) => handleClick(e, row)}
                        data-e2e={'users-row-cell-actions-delete'}
                        color="error"
                      >
                        <Delete />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="users-add-dlg-delete"
      >
        <DialogTitle id="responsive-dialog-title">{t('user_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('user_delete_ask')}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            className={classes.click_delete}
            onClick={() => handleConfirmDelete()}
            variant="outlined"
            color="error"
            data-e2e="users-add-dlg-delete-btn-delete"
          >
            {t('delete')}
          </Button>
          <Button
            className={classes.click_cancelar}
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="users-add-dlg-delete-btn-cancel"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContent>
  )
}
