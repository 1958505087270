import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { DeviceEvent, IpDevice, TDeviceSize, TModeView } from '../types'
import DeviceButton from '../dashboard/components/DeviceButton'
import PlayAlarmButton from '../dashboard/components/PlayAlarmButton'
import { getStyle } from './DevicesViewStyle'
import { MenuItemActions } from '../dashboard/components/ContextMenu'
import { useDispatch } from 'react-redux'
import { mqttRequest } from '../common/store/actions/mqtt/actions'
// import { volumeUpdateRequest } from '../common/store/actions/devices/actions'

const relayCommand = process.env?.REACT_APP_RELAY_TEMPORARY_OPEN
// const volumeCommand = process.env?.REACT_APP_VOLUME_COMMAND

const showMACinDeviceButton = false

interface IProps {
  viewMode: TModeView
  size?: TDeviceSize
  devices: DeviceEvent[]
  allowDevicesId: number[]
  rightMenu?: MenuItemActions[]
  speakers: IpDevice[] // VOLUME - TEMPORARY DESABLED, pasar speakers por props
}

const DeviceIconView = ({ size, devices, allowDevicesId, viewMode }: IProps) => {
  const style = getStyle(viewMode)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  // VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
  // const volumeHandler = (action: string, id: number) => {
  //   const speaker = speakers.find((speaker) => speaker.id === id)
  //   let volume = speaker.volume

  //   if (action === 'up') {
  //     volume = Math.min(31, volume + 1)
  //   } else if (action === 'down') {
  //     volume = Math.max(0, volume - 1)
  //   }

  //   const data = {
  //     body: { volume },
  //     id: speaker.id,
  //   }

  //   dispatch(volumeUpdateRequest(data))
  //   dispatch(
  //     mqttRequest({
  //       topic: `spkr/cmd/${speaker.device_id}`,
  //       payload: `${volumeCommand},${volume}`,
  //     }),
  //   )
  // }

  return (
    <Box sx={style.layoutStyle}>
      {devices
        .filter((device) => allowDevicesId.includes(device.id))
        .map((device) => {
          return (
            <Box
              key={device.id}
              m={2}
              sx={style.deviceStyle}
              data-e2e={'DeviceIconBox-' + device.id}
            >
              <DeviceButton
                viewMode={viewMode}
                device={device}
                size={size}
                rightMenu={[
                  {
                    label: t('relay_activate'),
                    onClick: () => {
                      dispatch(
                        mqttRequest({
                          topic: `spkr/cmd/${device.device_id}`,
                          payload: relayCommand,
                        }),
                      )
                      enqueueSnackbar(t('temp_open'), { variant: 'success' })
                    },
                  },
                  // VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
                  // {
                  //   label: t('volume_up'),
                  //   onClick: () => {
                  //     volumeHandler('up', device.id)
                  //   },
                  // },
                  // {
                  //   label: t('volume_down'),
                  //   onClick: () => {
                  //     volumeHandler('down', device.id)
                  //   },
                  // },
                ]}
              />
              <PlayAlarmButton viewMode={viewMode} device={device} size={size}></PlayAlarmButton>
              <Box style={style.textStyles ?? {}}>
                <Typography
                  variant="caption"
                  display="flex"
                  justifyContent="center"
                  sx={style.descStyle}
                  title={device.description}
                >
                  {device.description}
                </Typography>
                {showMACinDeviceButton ? (
                  <Typography variant="caption" display="flex" justifyContent="center">
                    {device.device_id}
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

export default DeviceIconView
