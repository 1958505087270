import { Logout } from '@mui/icons-material'
import ButtonListItem from './ButtonListItem'
import { useDispatch } from 'react-redux'
import { cleanLoginState } from '../../common/store/actions/user/actions'
import { useTranslation } from 'react-i18next'
import { getAPIService } from '../../getServerHost'
import { useNavigate } from 'react-router-dom'

export function makeDoLogout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return function doLogout() {
    fetch(`${getAPIService}/logout`, { redirect: 'follow' })
      .then((response) => {
        if (response.ok) {
          dispatch(cleanLoginState())
          navigate('/')
        }
      })
      .catch(function (err) {
        console.info(err)
      })
  }
}

interface IProps {
  'data-e2e'?: string
}

export default function ExitListItem(props: IProps) {
  const { t } = useTranslation()
  const doLogout = makeDoLogout()
  return (
    <ButtonListItem title={t('exit_title')} onClick={doLogout} data-e2e={props['data-e2e']}>
      <Logout />
    </ButtonListItem>
  )
}
