import axios from 'axios'
import { getAPIService } from '../../../getServerHost'
import { ILicenseRequest } from '../types'

export const getLicense = async () => {
  const response = await axios.get(`${getAPIService}/license`)
  return response.data
}

export const updateLicense = async ({ license }: ILicenseRequest) => {
  const response = await axios.post(`${getAPIService}/license`, { license: license })
  return response.data
}
