import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { CommonContent } from '../components/CommonContent'
import DevicesIcon from '@mui/icons-material/Devices'
import { Done, Cancel } from '@mui/icons-material'
import FormSelect from '../components/FormSelect'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, Grid, MenuItem, TextField } from '@mui/material'
import { devicesState } from '../common/store/selectors/devices'
import { NewIpDevice } from '../types'
import {
  deviceCreateRequest,
  deviceDetailRequest,
  deviceUpdateRequest,
  devicesTypesRequest,
} from '../common/store/actions/devices/actions'

interface IFormInputs {
  selected_type: string
  device_id: string
  description: string
  video_url: string
  snapshot_url: string
}

const DeviceForm = () => {
  const { mac } = useParams()
  const isEdit = mac !== 'add'

  useEffect(() => {
    dispatch(devicesTypesRequest())
    if (isEdit) {
      const id = Number(mac)
      dispatch(deviceDetailRequest(id))
    }
  }, [mac])

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hasPrivilege } = usePrivilege()
  const { device, types, isLoading_dev } = useSelector(devicesState)
  const [isCamera, setIsCamera] = useState<boolean>(false)
  const activeProtocol = process.env?.REACT_APP_PAGING_MODE
  const { control, getValues, handleSubmit, reset } = useForm<IFormInputs>({
    defaultValues: {
      selected_type: isEdit && device ? t(device.type) : 'speaker',
      device_id: isEdit ? device?.device_id : '',
      description: isEdit ? device?.description : '',
      video_url: isEdit ? device?.video_url : '',
      snapshot_url: isEdit ? device?.snapshot_url : '',
    },
  })

  useEffect(() => {
    if (isEdit && device) {
      reset({
        selected_type: device.type || t('speaker'),
        device_id: device.device_id || '',
        description: device.description || '',
        video_url: device.video_url || '',
        snapshot_url: device.snapshot_url || '',
      })
    }
  }, [device, isEdit, reset])

  const types_id = (type: string) => {
    switch (type) {
      case 'speaker':
        return 1
      case 'microphone':
        return 2
      case 'door':
        return 3
      case 'camera':
        return 4
      default:
        return 5
    }
  }

  const handleTypeChange = (selectedType: string) => {
    if (selectedType === 'camera') setIsCamera(true)
    //Si se implementa generic type llamar al genericForm
    else setIsCamera(false)
  }

  const onSubmit = (data: IFormInputs) => {
    if (isEdit) {
      if (device) {
        const updatedData: any = {
          description: data.description ? data.description : device.description,
        }
        const req = {
          id: device.id,
          type: device.type,
          body: updatedData,
        }
        dispatch(deviceUpdateRequest(req))
      }
    } else {
      if (data) {
        const { selected_type, description, device_id } = data
        const newDevice: NewIpDevice = {
          device_id: device_id,
          description: description,
          device_type_id: types_id(selected_type),
          protocol: activeProtocol,
        }

        if (selected_type === 'camera') {
          newDevice.snapshot_url = data.snapshot_url
          newDevice.video_url = data.video_url
        }

        //Si se implementa generic type llamar al genericForm

        const req = {
          body: newDevice,
          type: selected_type,
        }
        dispatch(deviceCreateRequest(req))
      }
    }
    navigate(`/devices`)
    reset({
      selected_type: '',
      device_id: '',
      description: '',
      video_url: '',
      snapshot_url: '',
    })
    setIsCamera(false)
  }

  return (
    <CommonContent
      title={isEdit ? t('edit_device') : t('add_device')}
      titleIcon={<DevicesIcon style={{ color: 'white' }} />}
      data-e2e={isEdit ? 'device-title-edit' : 'device-title-add'}
    >
      {isLoading_dev ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <form>
          <FormSelect
            control={control}
            id="selected_type"
            label={t('device_type')}
            size="small"
            fullWidth
            style={{
              textTransform: 'uppercase',
              textAlign: 'center',
              padding: '10px',
            }}
            onChange={(selectedType) => handleTypeChange(selectedType)}
            data-e2e="devices-add-fld-type-select"
          >
            {types.map((type) => (
              <MenuItem
                key={type.value}
                value={type.value}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {type.name}
              </MenuItem>
            ))}
          </FormSelect>
          <Grid container direction="column" spacing={3} mt={6}>
            <Grid item xs={12} md={6}>
              <Controller
                name="device_id"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('required'),
                  },
                  validate: () => {
                    const device = getValues('device_id')
                    const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/
                    if (!regex.test(device)) return t('not_mac')
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    data-e2e="device_id-fld"
                    value={value}
                    label={t('device_id')}
                    onChange={onChange}
                    helperText={error ? error.message : null}
                    error={!!error}
                    size="medium"
                    fullWidth
                    variant="outlined"
                    disabled={isEdit}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('required'),
                  },
                  validate: () => {
                    const name = getValues('description')
                    if (name.trim() !== name) return t('check_empty')
                  },
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    data-e2e="device-fld-desc"
                    value={value}
                    label={isEdit ? t('update_desc') : t('description')}
                    onChange={onChange}
                    helperText={error ? error.message : null}
                    error={!!error}
                    size="medium"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          {isCamera && (
            <Grid container direction="row" alignItems="center" spacing={3} m={6}>
              <Grid item xs={5}>
                <Controller
                  name="video_url"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                    validate: () => {
                      const url = getValues('video_url')
                      const regex =
                        /^(https?|ftp):\/\/(?:\S+(?::\S*)?@)?(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d{1,5})?|[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?::\d{1,5})?)(?:\/[^\s/$.?#].*)*$/
                      if (!regex.test(url)) return t('not_url')
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      error={!!error}
                      size="small"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={t('video_url')}
                      variant="outlined"
                      disabled={isEdit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name={'snapshot_url'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                    validate: () => {
                      const url = getValues('snapshot_url')
                      const regex = /^(https?|ftp):\/\/(?:\S+(?::\S*)?@)?[^\s/$.?#].[^\s]*$/
                      if (!regex.test(url)) return t('not_url')
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      helperText={error ? error.message : null}
                      error={!!error}
                      size="small"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      label={isEdit ? t('update_desc') : t('snapshot_url')}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" justifyContent="flex-end" spacing={2} mt={3}>
            <Grid item>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => navigate(`/devices`)}
                startIcon={<Cancel />}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item>
              {hasPrivilege(Privileges.ADD_TASKS) && (
                <Button
                  data-e2e="btn-add-task"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(onSubmit)()}
                  startIcon={<Done />}
                >
                  {isEdit ? t('save') : t('add')}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </CommonContent>
  )
}

export default DeviceForm
