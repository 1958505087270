import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { CommonContent } from '../../components/CommonContent'
import { CircularProgress, Typography } from '@mui/material'
import { SettingsRemote } from '@mui/icons-material'
import { rcusRequest, rcuCleanErrorRequest } from '../actions/rcus/actions'
import { rcusStates } from '../store/selectors/rcus'
import RcusTables from './RcusTables'
import RcusForm from './RcusForm'
import Paginate from '../components/Paginate'

const RcusView = () => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { rcus, isLoading, error } = useSelector(rcusStates)
  const [selectedPage, setSelectedPage] = useState('1')

  useEffect(() => {
    dispatch(rcusRequest({ limit: '4', page: selectedPage }))
  }, []) //ver si se pueden integrar los dos useEffect, este hace el primer dispatch

  useEffect(() => {
    dispatch(rcusRequest({ limit: '4', page: selectedPage }))
  }, [selectedPage])

  useEffect(() => {
    console.log('isLoading')
  }, [isLoading])

  useEffect(() => {
    if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(rcuCleanErrorRequest())
    }
  }, [error])

  return (
    <CommonContent
      title={t('rcu_devices')}
      titleIcon={<SettingsRemote style={{ color: 'white' }} />}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          {rcus.items.length ? (
            <RcusTables rcus={rcus.items} />
          ) : (
            <Typography variant="h6" color="text.secondary" align="center" p={10}>
              {t('no_items')}
            </Typography>
          )}
          {rcus.items.length ? (
            <Paginate
              pages={rcus.pages}
              currentPage={rcus.page}
              setSelectedPage={setSelectedPage}
            />
          ) : null}
          {hasPrivilege(Privileges.ADD_DEVICE) && <RcusForm />}
        </>
      )}
    </CommonContent>
  )
}

export default RcusView
