import { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { userProfileRequest } from '../common/store/actions/profile/actions'
import { userInfoRequest } from '../common/store/actions/user/actions'
import { getUserInfo } from '../common/store/selectors/user'

interface IProps {
  children: ReactNode
}

const ProfileProvider = ({ children }: IProps) => {
  const userInfoState = useSelector(getUserInfo)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (userInfoState.id && location.pathname) {
      console.log(userInfoState.id)
      dispatch(userInfoRequest({ username: 'me' }))
      dispatch(userProfileRequest({ user_id: userInfoState.id.toString() }))
    }
  }, [location.pathname])

  return <>{children}</>
}

export default ProfileProvider
