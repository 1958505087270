import axios from 'axios'
import { getAPIService } from '../../../getServerHost'
import { PageSet } from '../../../hip/types'

export const getIpDevices = async ({ limit, page }: PageSet) => {
  const response = await axios.get(
    `${getAPIService}/v2/api/ip-devices/devices?limit=${limit}&page=${page}`,
  )
  return response.data
}
