import { createReducer } from '@reduxjs/toolkit'
import { IProfile } from '../../api/types'
import {
  userProfileError,
  userProfileRequest,
  userProfileSuccess,
} from '../actions/profile/actions'

export interface UserProfileState {
  isLoading: boolean
  user_id?: string
  profiles: IProfile[]
  devices?: string[]
  success: string
  error: string
}

export const initialState = (): UserProfileState => {
  return {
    isLoading: false,
    user_id: undefined,
    profiles: undefined,
    devices: undefined,
    success: '',
    error: '',
  }
}

export const userProfileReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(userProfileRequest, (state) => {
      state.isLoading = true
      state.user_id = undefined
      state.devices = undefined
    })
    .addCase(userProfileSuccess, (state, { payload }) => {
      state.isLoading = false
      state.user_id = payload.user_id
      state.profiles = payload.profiles
      state.devices = payload._embedded.devices
    })
    .addCase(userProfileError, (state, { payload }) => {
      console.error('profileReducer - userProfileError', payload)
      state.error = payload
      state.isLoading = true
      state.profiles = []
      state.user_id = undefined
      state.devices = undefined
    })
})
