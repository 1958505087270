import { call, put } from '@redux-saga/core/effects'
import { wardsError, wardsSuccess } from '../../../actions/wards/actions'
import axios from 'axios'
import { Ward } from '../../../types'
import { getWards } from '../../../api/wards'

export function* sagaGetWards() {
  try {
    const response: Ward[] = yield call(getWards)
    yield put(wardsSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(wardsError(error.response.data as unknown))
    } else {
      yield put(wardsError(error))
      throw error
    }
  }
}
