import { Box } from '@mui/material'
import ButtonPanel from './ButtonPanel'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getAPIService } from '../../getServerHost'
import { cleanLoginState } from '../../common/store/actions/user/actions'

const ExitButton = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <ButtonPanel
      variant="contained"
      widthAndHeight={72}
      color="primary"
      onClick={() => {
        fetch(`${getAPIService}/logout`, { redirect: 'follow' })
          .then((response) => {
            if (response.ok) {
              dispatch(cleanLoginState())
              navigate('/')
            }
          })
          .catch(function (err) {
            console.info(err)
          })
      }}
      tooltip="Reset all devices"
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Box m={4} width="100%" height="100%">
          <ArrowBackIcon
            sx={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </ButtonPanel>
  )
}

export default ExitButton
