import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { Divider, List, MenuList } from '@mui/material'
import {
  AudioFile,
  Dashboard,
  DevicesOther,
  Group,
  Lyrics,
  PermContactCalendar,
  Radio,
  Schedule,
  SpeakerGroup,
  Verified,
} from '@mui/icons-material'
import MenuItem from '../../components/menu/MenuListItem'
import { getUserProfileState } from '../../common/store/selectors/profiles'
import { TTradFunc } from '../../types'

interface IProps {
  t: TTradFunc
}

export default function PagingMenuBar({ t }: IProps) {
  const isMulticast = process.env?.REACT_APP_PAGING_MODE == 'MULTICAST'
  const { hasPrivilege } = usePrivilege()
  const { profiles } = useSelector(getUserProfileState)

  const allPrivileges = useMemo(
    () =>
      profiles
        ? profiles.reduce((acc, profile) => {
            return [...acc, ...profile.privileges]
          }, [])
        : [],
    [profiles],
  )

  const hasPermission = (privilege: Privileges) => {
    return allPrivileges.includes(privilege) || allPrivileges.includes(Privileges.ALL)
  }

  return (
    <>
      <MenuList>
        <List>
          {hasPrivilege(Privileges.VIEW_DASHBOARD) && (
            <MenuItem
              title={t('dashboard')}
              icon={<Dashboard />}
              to="/Dashboard"
              data-e2e="navbar-dashboard"
            />
          )}
        </List>
        <Divider />
        <List>
          {hasPrivilege(Privileges.VIEW_DEVICE) && (
            <MenuItem
              title={t('devices')}
              icon={<DevicesOther />}
              to="/devices"
              data-e2e="navbar-devices"
            />
          )}
          {hasPrivilege(Privileges.VIEW_ZONES) && (
            <MenuItem
              title={t('zones')}
              icon={<SpeakerGroup />}
              to="/groups"
              data-e2e="navbar-zones"
            />
          )}
          {hasPrivilege(Privileges.VIEW_PROFILES) && (
            <MenuItem
              title={t('profiles')}
              icon={<PermContactCalendar />}
              to="/profiles"
              data-e2e="navbar-profiles"
            />
          )}
          {hasPrivilege(Privileges.VIEW_USERS) && (
            <MenuItem
              title={t('user_title')}
              icon={<Group />}
              to="/users"
              data-e2e="navbar-users"
            />
          )}
          {hasPrivilege(Privileges.VIEW_MESSAGE_FILES) && (
            <MenuItem
              title={t('msg-files')}
              icon={<Lyrics />}
              to="/msg-files"
              data-e2e="navbar-msg-files"
            />
          )}
          {hasPermission(Privileges.VIEW_MUSIC_FILES) && isMulticast && (
            <MenuItem
              title={t('music-files')}
              icon={<AudioFile />}
              to="/music-files"
              data-e2e="navbar-music-files"
            />
          )}
          {hasPrivilege(Privileges.VIEW_TASKS) && (
            <MenuItem title={t('tasks')} icon={<Schedule />} to="/tasks" data-e2e="navbar-tasks" />
          )}
          {hasPermission(Privileges.VIEW_RADIOS) && isMulticast && (
            <MenuItem title={t('radios')} icon={<Radio />} to="/radios" data-e2e="navbar-radios" />
          )}
          {hasPrivilege(Privileges.VIEW_LICENSE) && (
            <MenuItem
              title={t('license_title')}
              icon={<Verified />}
              to="/license"
              data-e2e="navbar-license"
            />
          )}
        </List>
      </MenuList>
    </>
  )
}
