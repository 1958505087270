import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { CommonContent } from '../components/CommonContent'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Reply, Schedule } from '@mui/icons-material'
import { tasksStates } from '../common/store/selectors/tasks'
import {
  tasksCleanSuccessRequest,
  tasksCleanErrorRequest,
  tasksRequest,
} from '../common/store/actions/tasks/actions'
import Paginate from '../hip/components/Paginate'
import TasksTable from './TasksTable'
import { Tasks } from '../types'

const TasksView = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { hasPrivilege } = usePrivilege()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tasks, exceptions, isLoading, success, error } = useSelector(tasksStates)
  const { type } = useParams()
  const { items, pages, page } = tasks
  const [selectedPage, setSelectedPage] = useState('1')

  useEffect(() => {
    dispatch(tasksRequest({ pageSet: { limit: '4', page: selectedPage }, type: type }))
  }, [selectedPage, type])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(tasksCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(tasksCleanErrorRequest())
    }
  }, [success, error])

  return (
    <CommonContent
      title={t(`${type}`)}
      titleIcon={<Schedule style={{ color: 'white' }} data-e2e={`title-${type}`} />}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
            {hasPrivilege(Privileges.ADD_TASKS) && (
              <Button
                data-e2e="tasks-btn-add"
                variant="contained"
                color="success"
                onClick={() => navigate(`/tasks/${type}/add`)}
                startIcon={<Schedule />}
              >
                {t(`add_${type}`)}
              </Button>
            )}
            <Button
              data-e2e="task-btn-back"
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/tasks')}
              startIcon={<Reply />}
            >
              {t('back')}
            </Button>
          </div>
          {items.length ? (
            <TasksTable tasks={items as Tasks[]} type={type} exceptions={exceptions} />
          ) : (
            <Typography variant="h6" color="text.secondary" align="center" p={10}>
              {t('no_items')}
            </Typography>
          )}
          {items.length ? (
            <Paginate pages={pages} currentPage={page} setSelectedPage={setSelectedPage} />
          ) : null}
        </>
      )}
      {hasPrivilege(Privileges.ADD_TASKS) && type === 'exception_rules' && (
        <>
          <Box mb={4} key={type}>
            <Button
              data-e2e={`tasks-btn-add_ex_contx`}
              onClick={() => navigate(`/tasks/add_exceptions`)}
              variant="contained"
              startIcon={<Schedule />}
            >
              {t(`add_exception_context`)}
            </Button>
          </Box>
        </>
      )}
    </CommonContent>
  )
}

export default TasksView
