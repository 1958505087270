import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, TextField } from '@mui/material'
import { AddCircle, Cancel } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import { HipUserFormValues } from '../types'
import { hip_userCreateRequest, hip_userUpdateRequest } from '../actions/hipUsers/actions'
import { topicGenerator, multicast_acrt, ipMulticast } from '../../Utils'
import { userIcon } from './utilsHipUser'

interface HipUserFormProps {
  userType: string
  initialValues?: HipUserFormValues
  setOpen: (open: boolean) => void
}

const HipUserForm = ({ userType, initialValues, setOpen }: HipUserFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const param = useParams()
  const { handleSubmit, reset, control, getValues } = useForm<HipUserFormValues>()

  const isEditForm = !!initialValues

  const handleClose = () => {
    if (isEditForm) navigate(`/${userType}`)
    else setOpen(false)
  }

  const onSubmit = handleSubmit((data: HipUserFormValues) => {
    const { username, password, name, key } = data
    const { id } = param
    if (isEditForm) {
      const updatedData: HipUserFormValues = {
        username,
        password,
        name,
      }
      const newData = {
        data: updatedData,
        id: id.toString(),
      }
      dispatch(hip_userUpdateRequest(newData))
    } else {
      const userData = {
        user: {
          username,
          password,
          name,
          mqtt_topic: topicGenerator(),
          multicast_ip: `224.0.0.${ipMulticast}`,
          multicast_port: multicast_acrt,
          active: true,
        },
        key,
        profiles: userType === 'nurses' ? [2] : [4],
      }
      if (data) dispatch(hip_userCreateRequest(userData))
    }
    reset({
      username: '',
      password: '',
      name: '',
      key: '',
    })
  })

  return (
    <CommonContent
      title={isEditForm ? t(`edit_${userType}`) : t(`add_${userType}`)}
      titleIcon={userIcon(userType)}
      data-e2e={isEditForm ? 'nrs-title-edit' : 'nrs-title-add'}
    >
      <Box width={'75%'} p={'35px'}>
        <form>
          <Controller
            name="username"
            control={control}
            defaultValue={initialValues ? initialValues.username : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
              validate: () => {
                const name = getValues('username')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="nrs-user-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={initialValues ? t('update_username') : t('username')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name={'password'}
            control={control}
            defaultValue={initialValues && initialValues.password ? initialValues.password : ''}
            rules={{
              // required: {
              //   value: true,
              //   message: t('required'),
              // },
              validate: () => {
                const name = getValues('password')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="nrs-pass-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={initialValues ? t('update_password') : t('password')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name={'name'}
            control={control}
            defaultValue={initialValues ? initialValues.name : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="nrs-name-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={initialValues ? t('update_name') : t('name')}
                variant="outlined"
              />
            )}
          />
          {!isEditForm && (
            <Controller
              name={'key'}
              control={control}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const key = getValues('key')
                  if (key.trim() !== key) return t('check_empty')
                  const regex = /^\d{8}$/
                  if (!regex.test(key)) return t('not_key')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="nrs-key-fld"
                  style={{ paddingBottom: 20 }}
                  fullWidth
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  label={t('add_card')}
                  variant="outlined"
                />
              )}
            />
          )}
        </form>
      </Box>
      <Grid container direction="row" justifyContent="flex-end" spacing={2} paddingRight={'35px'}>
        <Grid item>
          <Button
            data-e2e="add-btn-cancel"
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            startIcon={<Cancel />}
          >
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-e2e={isEditForm ? 'nrs-btn-save' : 'nrs-btn-add'}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            startIcon={<AddCircle />}
          >
            {isEditForm ? t('save') : t('add')}
          </Button>
        </Grid>
      </Grid>
    </CommonContent>
  )
}

export default HipUserForm
