import axios from 'axios'
import { getAPIService } from '../../getServerHost'
import { HipUserFormValues } from '../types'

export const userUpdate = async (payload: { data: HipUserFormValues; id: string }) => {
  const { id, data } = payload
  const response = await axios.patch(`${getAPIService}/users/${id}`, {
    username: data.username,
    password: data.password,
    name: data.name,
  })
  return response.data
}
