import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { User } from '../../../../types'
import { userCreate } from '../../../api/userCreate'
import { hip_userCreateSuccess, hip_userCreateError } from '../../../actions/hipUsers/actions'
import { addProfile } from '../../../api/userAddProfile'
import { hipUserDetail } from '../../../api/hipUserDetail'
import { keyCreate } from '../../../api/keyCreate'

export function* sagaUserCreate({
  payload,
}: Action<{ user: User; profiles: number[]; key: string }>) {
  try {
    const response: User = yield call(userCreate, payload.user)
    const profileData = {
      user_id: response.id,
      profiles: payload.profiles,
    }
    const response2: User = yield call(addProfile, profileData)
    if (payload.key) {
      const keyData = {
        key: payload.key,
        user_id: response2.user_id,
      }
      yield call(keyCreate, keyData)
    }
    const userCreated: User = yield call(hipUserDetail, response2.user_id)
    yield put(hip_userCreateSuccess(userCreated))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(hip_userCreateError(error.response.data as unknown))
    } else {
      yield put(hip_userCreateError(error))
      throw error
    }
  }
}
