import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { HipUserFormValues, User_Key } from '../../../types'
import { User } from '../../../../types'
import { userUpdate } from '../../../api/userUpdate'
import { hip_userUpdateError, hip_userUpdateSuccess } from '../../../actions/hipUsers/actions'
import { hipUserDetail } from '../../../api/hipUserDetail'

export function* sagaNurseUpdate({ payload }: Action<{ data: HipUserFormValues; id: string }>) {
  try {
    const response: User = yield call(userUpdate, payload)
    const response2: User_Key = yield call(hipUserDetail, response.id)
    yield put(hip_userUpdateSuccess(response2))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(hip_userUpdateError(error.response.data as unknown))
    } else {
      yield put(hip_userUpdateError(error))
      throw error
    }
  }
}
