import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { TTradFunc } from '../../types'
import { FileDownload } from '@mui/icons-material'
import { getAPIService } from '../../getServerHost'

interface IProps {
  t: TTradFunc
}

export default function ApplicationLogs({ t }: IProps) {
  return (
    <List>
      <ListItemButton
        data-e2e="navbar-application-logs"
        component="a"
        href={`${getAPIService}/v2/api/application-log/csv`}
      >
        <ListItemIcon>
          <FileDownload />
        </ListItemIcon>
        <ListItemText primary={t('application_logs')} />
      </ListItemButton>
    </List>
  )
}
