import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SSEProvider, useSSE } from 'react-hooks-sse'
import { getUserInfo } from '../../common/store/selectors/user'
import { getUserProfileState } from '../../common/store/selectors/profiles'
import { getAPIService, getSSEService } from '../../getServerHost'
import { IWelcome } from '../../types'
import axios from 'axios'
import AdminButton from '../../dashboard/components/AdminButton'
import { Box, Button, Container, CssBaseline } from '@mui/material'
import IconsInfo from './IconsInfo'
import RoomsViews from './RoomsViews'

interface IRoomsBoardProps {
  isMobileMode?: boolean
}

const Welcome = () => {
  const welcome = useSSE<IWelcome>('welcome', { events: [] })
  useEffect(() => {
    // Fire devices event
    welcome.events.forEach((event) => axios.get(`${getAPIService}/event/${event}`))
  }, [welcome])
  return <></>
}

const RoomsBoard = ({ isMobileMode }: IRoomsBoardProps) => {
  const { t } = useTranslation()
  const userInfoState = useSelector(getUserInfo)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading } = useSelector(getUserProfileState)
  const isDesktopMode = !isMobileMode

  const [onlyEvents, setOnlyEvents] = useState<boolean>(false)
  const handleToggle = () => setOnlyEvents(!onlyEvents)

  return (
    <>
      {!isLoading && (
        <SSEProvider endpoint={`${getSSEService}/events?user_id=${userInfoState.id}`}>
          <CssBaseline />
          <Container maxWidth="xl">
            {/* {isDesktopMode ? <div>Desktop</div> : <div>Mobile</div>} */}
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box m={4} display="flex" flexWrap="wrap">
                <Box m={2}>
                  <AdminButton />
                </Box>
                <Box m={2}>
                  <Button variant="contained" color="primary" type="submit" onClick={handleToggle}>
                    {onlyEvents ? t('all_rooms') : t('only_events')}
                  </Button>
                </Box>
                <IconsInfo />
              </Box>
              <Welcome />
            </Box>
            <RoomsViews isDesktopMode={isDesktopMode} onlyEvents={onlyEvents} />
          </Container>
        </SSEProvider>
      )}
    </>
  )
}

export default RoomsBoard
