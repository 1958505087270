import { Box, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSSE } from 'react-hooks-sse'
import { useSelector } from 'react-redux'
import { getUserProfileState } from '../../common/store/selectors/profiles'
import { DeviceEvent, IpDevice, TDeviceSize, TModeView } from '../../types'
import DeviceIconView from '../../components/DevicesIconView'
import { IDeviceOrdering, DeviceSortList } from '../../devices/DeviceSortList'

interface IOutputDevicesProps {
  isMobileMode?: boolean
  speakers?: IpDevice[] // VOLUME - TEMPORARY DESABLED
}

interface IViewMode {
  mode: TModeView
}

export const OutputDevices = ({ isMobileMode, speakers }: IOutputDevicesProps) => {
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter((device) => device.type !== 'rcu')
  const [viewMode, setViewMode] = useState<IViewMode>({ mode: 'large-icon' })
  const [deviceSize, setDeviceSize] = useState<TDeviceSize>(120)
  const [outputDevices, setOutputDevices] = useState<DeviceEvent[]>([])
  const { devices: devicesIdStrings, isLoading } = useSelector(getUserProfileState)
  const [deviceOrdering, setDeviceOrdering] = useState<IDeviceOrdering>({
    orderBy: 'desc',
    ascendent: true,
  })
  // const deviceSize = isMobileMode ? 90 : 120

  useEffect(() => {
    const list = devices.filter((device) => device.type !== 'microphone')
    // console.log('list before', list)
    const direction = deviceOrdering.ascendent ? 1 : -1
    switch (deviceOrdering.orderBy) {
      case 'desc':
        list.sort((a, b) => direction * (a.description > b.description ? 1 : -1))
        break
      case 'id':
        list.sort((a, b) => direction * (a.device_id > b.device_id ? 1 : -1))
        break
      default:
        break
    }
    // console.log('list after', list)
    setOutputDevices(list)
  }, [allDevices, deviceOrdering])

  useEffect(() => {
    switch (viewMode.mode) {
      case 'large-icon':
        setDeviceSize(isMobileMode ? 90 : 120)
        break
      case 'medium-icon':
        setDeviceSize(isMobileMode ? 72 : 100)
        break
      case 'small-icon':
        setDeviceSize(isMobileMode ? 64 : 72)
        break
      case 'list':
        setDeviceSize(isMobileMode ? 32 : 32)
        break
      default:
        break
    }
  }, [viewMode])

  return (
    <>
      {!isLoading && devicesIdStrings && (
        <>
          <Box>
            <DeviceSortList
              ordering={deviceOrdering}
              onOrderChange={(ev) => setDeviceOrdering(ev.ordering)}
              allowById={false}
            />
            <Select
              label="View"
              defaultValue={viewMode.mode}
              size="small"
              onChange={(event) => setViewMode({ mode: event.target.value as TModeView })}
              data-e2e="viewModeSelect"
            >
              <MenuItem value="large-icon">Large Icon</MenuItem>
              <MenuItem value="medium-icon">Medium Icon</MenuItem>
              <MenuItem value="small-icon">Small Icon</MenuItem>
              <MenuItem value="list">List</MenuItem>
            </Select>
          </Box>
          <Box
            data-e2e="device-list"
            display="flex"
            justifyContent="center"
            width="100%"
            flexWrap="wrap"
          >
            <DeviceIconView
              viewMode={viewMode.mode}
              size={deviceSize}
              devices={outputDevices}
              allowDevicesId={devicesIdStrings.map(Number)}
              speakers={speakers} // VOLUME - TEMPORARY DESABLED
            />
          </Box>
        </>
      )}
    </>
  )
}
