import { Divider, MenuList } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PagingMenuBar from './paging/menu/PagingMenuBar'
import LogoutMenuItem from './components/menu/LogoutMenuItem'
import HipMenuBar from './hip/HipMenuBar'
import ApplicationLogs from './components/menu/ApplicationLogs'

const productName = process.env?.REACT_APP_PRODUCT_NAME

const MenuBar = () => {
  const { t } = useTranslation()

  return (
    <MenuList>
      {productName === 'HELP_IP' ? <HipMenuBar t={t} /> : <PagingMenuBar t={t} />}
      <Divider />
      <ApplicationLogs t={t} />
      <LogoutMenuItem />
    </MenuList>
  )
}

export default MenuBar
