import { Divider, List, MenuList } from '@mui/material'
import {
  BedroomChild,
  Dashboard,
  Group,
  Handyman,
  LocalHospital,
  PermContactCalendar,
  SettingsRemote,
  Verified,
} from '@mui/icons-material'
import { TTradFunc } from '../types'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import MenuItem from '../components/menu/MenuListItem'

interface IProps {
  t: TTradFunc
}

const e2eprefix = 'navbar-'

export default function HipMenuBar({ t }: IProps) {
  const { hasPrivilege } = usePrivilege()

  return (
    <MenuList>
      <List>
        {hasPrivilege(Privileges.VIEW_DASHBOARD) && (
          <MenuItem
            title={t('roomsboard')}
            icon={<Dashboard />}
            to="/roomsboard"
            data-e2e={`${e2eprefix}roomsboard`}
          />
        )}
      </List>
      <Divider />
      <List>
        {hasPrivilege(Privileges.VIEW_DEVICE) && (
          <MenuItem
            title={t('rcu_devices')}
            icon={<SettingsRemote />}
            to="/rcus"
            data-e2e={`${e2eprefix}rcus`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_ROOMS) && (
          <MenuItem
            title={t('rooms')}
            icon={<BedroomChild />}
            to="/rooms"
            data-e2e={`${e2eprefix}rooms`}
          />
        )}
        {/* {hasPrivilege(Privileges.VIEW_WARDS) && (
          <MenuItem
            title={t('wards')}
            icon={<MeetingRoom />}
            to="/wards"
            data-e2e="navbar-wards"
          />
        )} */}
        {hasPrivilege(Privileges.VIEW_NURSES) && (
          <MenuItem
            title={t('nurses')}
            icon={<LocalHospital />}
            // icon={<NurseIcon style={{ width: 35, height: 35, fill: 'grey' }} />}
            to="/nurses"
            data-e2e={`${e2eprefix}nurses`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_MAINTENANCE) && (
          <MenuItem
            title={t('maintenance')}
            icon={<Handyman />}
            to="/maintenance"
            data-e2e={`${e2eprefix}maintenance`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_PROFILES) && (
          <MenuItem
            title={t('profiles')}
            icon={<PermContactCalendar />}
            to="/profiles"
            data-e2e="navbar-profiles"
          />
        )}
        {hasPrivilege(Privileges.VIEW_USERS) && (
          <MenuItem title={t('user_title')} icon={<Group />} to="/users" data-e2e="navbar-users" />
        )}
        {hasPrivilege(Privileges.VIEW_LICENSE) && (
          <MenuItem
            title={t('license_title')}
            icon={<Verified />}
            to="/license"
            data-e2e="navbar-license"
          />
        )}
      </List>
    </MenuList>
  )
}
