import axios from 'axios'
import { getAPIService } from '../../../getServerHost'

export const uploadFile = async (payload: { body: FormData; type: string }) => {
  const body = payload.body
  const response = await axios.post(`${getAPIService}/${payload.type}`, body)
  return response.data
}

//Descomentar junto con el saga para enviar progreso de carga al front,
//agregar un estado loading:number al reducer
// import axios from 'axios'
// import { getAPIService } from '../../../getServerHost'
// export const uploadFile = (body: FormData, type: string, onUploadProgress:((progressEvent: ProgressEvent) => void)) => {
//  return axios.post(`${getAPIService}/${type}`, body, { onUploadProgress })
// }
