import axios from 'axios'
import { getAPIService } from '../../getServerHost'
import { KeyFormValues } from '../types'

export const keyUpdate = async (keyFormValues: KeyFormValues, key_id: number) => {
  const { key, user_id } = keyFormValues
  const response = await axios.patch(`${getAPIService}/v2/api/access-keys/${key_id}`, {
    key,
    user_id,
  })
  return response.data
}
