import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ButtonPanel from './ButtonPanel'

const AdminButton = () => {
  const navigate = useNavigate()
  return (
    <ButtonPanel
      data-e2e="btn-admin"
      variant="contained"
      color="primary"
      widthAndHeight={72}
      onClick={() => {
        navigate('/')
      }}
    >
      <Box p={3}>
        <Box
          display="flex"
          component="img"
          src="/menu.png"
          sx={{ objectFit: 'contain' }}
          width="100%"
          height="100%"
        />
      </Box>
    </ButtonPanel>
  )
}

export default AdminButton
