import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import { BedroomChild, AddCircle, Done } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import { roomCreateRequest, roomUpdateRequest } from '../actions/rooms/actions'
import { availableRcusRequest } from '../actions/rcus/actions'
import { rcusStates } from '../store/selectors/rcus'
import { Room, UpdatedRoomdData } from '../types'

export type RoomFormValues = {
  name: string
}

interface RoomFormProps {
  isEdit: boolean
  room?: Room
}

const RoomForm = ({ isEdit, room }: RoomFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { availablesRcus } = useSelector(rcusStates)
  const [selectedRcus, setSelectedRcus] = useState<number[]>([])
  const { control, handleSubmit, reset, getValues } = useForm<RoomFormValues>()

  useEffect(() => {
    dispatch(availableRcusRequest())
    if (isEdit && room) {
      const currentRcus = room.rcus.map((rcu) => rcu.id)
      setSelectedRcus(currentRcus)
      reset({
        name: room.name,
      })
    }
  }, [isEdit, room])

  const onSubmit = handleSubmit((data: RoomFormValues) => {
    if (data) {
      const updatedData: UpdatedRoomdData = {
        id: isEdit ? room?.id : undefined,
        name: data.name,
        rcus: selectedRcus,
      }
      if (isEdit) {
        dispatch(roomUpdateRequest(updatedData))
      } else {
        dispatch(roomCreateRequest(updatedData))
      }
      setSelectedRcus([])
      reset({
        name: '',
      })
    }
  })

  return (
    <CommonContent
      title={isEdit ? t('edit_room') : t('Add a room')}
      titleIcon={<BedroomChild style={{ color: 'white' }} />}
    >
      <form>
        <Grid container direction="row" alignItems="center" spacing={4} mt={3}>
          <Grid item xs={5}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const name = getValues('name')
                  if (name.trim() !== name) return t('check_empty')
                },
              }}
              defaultValue={isEdit ? room?.name : ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="rooms-fld-name"
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEdit ? t('update_name') : t('name')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl
              component="fieldset"
              fullWidth
              size="medium"
              variant="outlined"
              style={{ margin: '20px' }}
            >
              <div>
                <Typography color="text.secondary" data-e2e="rooms-text-avRcus">
                  {availablesRcus.length ? t('available_devices') : t('available_devices_not')}
                </Typography>
                {availablesRcus.map((rcu) => (
                  <FormControlLabel
                    key={rcu.id}
                    control={
                      <Checkbox
                        data-e2e={'rooms-rcu-avail-' + rcu.id}
                        checked={selectedRcus.includes(rcu.id)}
                        onChange={(event) => {
                          const isChecked = event.target.checked
                          setSelectedRcus((prevSelected) => {
                            if (isChecked) {
                              return [...prevSelected, rcu.id]
                            } else {
                              return prevSelected.filter((id) => id !== rcu.id)
                            }
                          })
                        }}
                        name={`rcu_${rcu.id}`}
                        color="primary"
                      />
                    }
                    label={rcu.description}
                  />
                ))}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" justifyContent="flex-end" spacing={2} mt={3}>
        <Grid item>
          <Button
            data-e2e={isEdit ? 'rooms-btn-save' : 'rooms-btn-add'}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            startIcon={<AddCircle />}
            disabled={!selectedRcus.length}
          >
            {isEdit ? t('save') : t('add')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-e2e="rooms-btn-done"
            variant="contained"
            color="success"
            type="submit"
            onClick={isEdit ? () => navigate('/rooms') : () => navigate('/roomsboard')}
            startIcon={<Done />}
          >
            {t('done')}
          </Button>
        </Grid>
      </Grid>
    </CommonContent>
  )
}

export default RoomForm
