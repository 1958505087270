import { List } from '@mui/material'
import ExitItemList from './ExitListItem'

/* interface IProps {
  t: TTradFunc
} */

export default function LogoutMenuItem() {
  return (
    <List>
      <ExitItemList data-e2e="navbar-exit" />
    </List>
  )
}
