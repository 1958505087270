export const ROOMS_ERROR = '@api/ROOMS_ERROR'
export const ROOMS_REQUEST = '@api/ROOMS_REQUEST'
export const ROOMS_SUCCESS = '@api/ROOMS_SUCCESS'

export const ROOM_DETAIL_REQUEST = '@api/ROOM_DETAIL_REQUEST'
export const ROOM_DETAIL_SUCCESS = '@api/ROOM_DETAIL_SUCCESS'
export const ROOM_DETAIL_ERROR = '@api/ROOM_DETAIL_ERROR'

export const ROOM_CREATE_ERROR = '@api/ROOM_CREATE_ERROR'
export const ROOM_CREATE_REQUEST = '@api/ROOM_CREATE_REQUEST'
export const ROOM_CREATE_SUCCESS = '@api/ROOM_CREATE_SUCCESS'

export const ROOM_DELETE_REQUEST = '@api/ROOM_DELETE_REQUEST'
export const ROOM_DELETE_SUCCESS = '@api/ROOM_DELETE_SUCCESS'
export const ROOM_DELETE_ERROR = '@api/ROOM_DELETE_ERROR'

export const ROOM_UPDATE_REQUEST = '@api/ROOM_UPDATE_REQUEST'
export const ROOM_UPDATE_SUCCESS = '@api/ROOM_UPDATE_SUCCESS'
export const ROOM_UPDATE_ERROR = '@api/ROOM_UPDATE_ERROR'

export const ROOM_CLEAN_SUCCESS_REQUEST = '@api/ROOM_CLEAN_SUCCESS_REQUEST'
export const ROOM_CLEAN_ERROR_REQUEST = '@api/ROOM_CLEAN_ERROR_REQUEST'
