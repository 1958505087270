import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { TDeviceSize, TModeView } from '../../types'
import { Room, IRcu, ButtonAtr, MessageNote, HipIconType, RcuEvent } from '../types'
import HipButton from '../components/HipButton'
import { getButtonAttributesAndMessage, oldEvent } from './BoardAuxiliar'
import { getStyle } from '../../components/DevicesViewStyle'
import ButtonText from './ButtonText'

interface IProps {
  room: Room
  rcus: IRcu[]
  deviceSize: TDeviceSize
  onlyEvents: boolean
  viewMode: TModeView
}

const RoomButton = ({ room, rcus, deviceSize, onlyEvents, viewMode }: IProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const style = getStyle(viewMode)

  const feedbacks = rcus?.map((rcu) => ({
    id: rcu.id,
    feedback: rcu.feedback,
  }))

  const [feedbackStack, setFeedbackStack] = useState([])

  const handleNewEvent = (newRcus: RcuEvent[]) => {
    const updatedStack = [...feedbackStack, newRcus]
    if (updatedStack.length > 3) {
      updatedStack.shift()
    }
    setFeedbackStack(updatedStack)
  }

  useEffect(() => {
    if (feedbacks) {
      handleNewEvent(feedbacks)
    }
  }, [rcus])

  const maintenanceFeedbacks = feedbacks?.filter((event) => event.feedback?.new === 'MnCard')
  const codeBFeedbacks = feedbacks?.filter((event) => event.feedback?.new === 'CBlue')
  const nurseFeedbacks = feedbacks?.filter(
    (event) => event.feedback?.new === 'NCard' || event.feedback?.new === 'NBt',
  )
  const eosFeedbacks = feedbacks?.filter((event) => event.feedback?.new === 'EoS')
  const otherFeedbacks = feedbacks?.filter(
    (event) =>
      event.feedback?.new !== 'MnCard' &&
      event.feedback?.new !== 'CBlue' &&
      event.feedback?.new !== 'NCard' &&
      event.feedback?.new !== 'NBt' &&
      event.feedback?.new !== 'EoS',
  )
  const [currentFeedback, setCurrentFeedback] = useState<RcuEvent>({
    id: null,
    feedback: null,
  })
  const [feedbackIndex, setFeedbackIndex] = useState(0)

  const updateFeedback = () => {
    //el bug está en la imposibilidad de definir antigüedad después de un cierto tiempo
    //porque el stack se sigue llenando con eventos repetidos y llega un punto en que todos los pasados feedbacks son iguales
    let alterFeedback
    if (feedbacks.length > 1) {
      if (eosFeedbacks.length !== 0) {
        alterFeedback = feedbacks.find((feedback) => feedback.feedback.new !== 'EoS')
        const otherEvent = alterFeedback ? alterFeedback.feedback.new : null
        if (oldEvent(otherEvent, feedbackStack, feedbacks)) {
          setCurrentFeedback(eosFeedbacks[0])
          return
        } else {
          if (nurseFeedbacks.length !== 0) {
            setCurrentFeedback(nurseFeedbacks[0])
            return
          } else if (codeBFeedbacks.length !== 0) {
            setCurrentFeedback(codeBFeedbacks[0])
            return
          } else if (otherFeedbacks.length !== 0) {
            setCurrentFeedback(otherFeedbacks[0])
            return
          }
        }
      }
      if (nurseFeedbacks.length !== 0) {
        const nurseEvent = nurseFeedbacks[0].feedback.new
        alterFeedback = feedbacks.find((feedback) => feedback.feedback.new !== nurseEvent)
        const otherEvent = alterFeedback ? alterFeedback.feedback.new : null
        if (oldEvent(otherEvent, feedbackStack, feedbacks)) {
          setCurrentFeedback(nurseFeedbacks[0])
          return
        } else {
          if (codeBFeedbacks.length !== 0) {
            setCurrentFeedback(codeBFeedbacks[0])
            return
          } else if (otherFeedbacks.length !== 0) {
            setCurrentFeedback(otherFeedbacks[0])
            return
          }
        }
      }
      if (codeBFeedbacks.length !== 0) {
        alterFeedback = feedbacks.find((feedback) => feedback.feedback.new !== 'CBlue')
        const otherEvent = alterFeedback ? alterFeedback.feedback.new : null
        if (oldEvent(otherEvent, feedbackStack, feedbacks)) {
          setCurrentFeedback(codeBFeedbacks[0])
          return
        } else {
          if (otherFeedbacks.length !== 0) {
            setCurrentFeedback(otherFeedbacks[0])
            return
          }
        }
      }
      if (maintenanceFeedbacks.length > 1) {
        const nextIndex = (feedbackIndex + 1) % otherFeedbacks.length
        setFeedbackIndex(nextIndex)
        setCurrentFeedback(maintenanceFeedbacks[nextIndex])
        return
      }
      if (otherFeedbacks.length > 1) {
        const nextIndex = (feedbackIndex + 1) % otherFeedbacks.length
        setFeedbackIndex(nextIndex)
        setCurrentFeedback(otherFeedbacks[nextIndex])
        return
      }
    } else {
      setCurrentFeedback(feedbacks[0])
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (feedbackStack) {
        updateFeedback()
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [codeBFeedbacks, nurseFeedbacks, eosFeedbacks, otherFeedbacks, feedbackIndex])

  const defaultButton: ButtonAtr = {
    image: 'ERROR',
    // color: 'inherit',
    fill: '#354652',
  }

  const defaultMessage: MessageNote = {
    message: null,
  }

  const { attributes: rcuAttributes, message: rcuMessage } = currentFeedback
    ? getButtonAttributesAndMessage(currentFeedback)
    : { attributes: defaultButton, message: defaultMessage }

  const bedNumber = currentFeedback?.feedback?.params?.bed_no || ''
  const accessKey = currentFeedback?.feedback?.params?.card || ''
  const user_name = currentFeedback?.feedback?.params?.user_name || ''

  const rcuInfo = [
    {
      attributes: rcuAttributes,
      name: room.name,
      bed: bedNumber ? `Bed ${bedNumber}` : undefined,
      user_name: user_name ? `${user_name}` : undefined,
      accessKey: accessKey ? `Id ${accessKey}` : undefined,
    },
  ]

  useEffect(() => {
    if (room && rcuMessage.message) {
      enqueueSnackbar(`${room.name} ${bedNumber} ${rcuMessage.message}`, {
        variant: 'warning',
        persist: true,
        preventDuplicate: true,
      })
    }
  }, [rcuMessage.message])

  return (
    <div>
      <Box display="flex" flexWrap="wrap" m={10}>
        {onlyEvents
          ? rcuInfo[0].attributes.image !== 'OK' &&
            rcuInfo.map((button, index) => (
              <div key={index} style={{ margin: '10px', maxWidth: '200px' }}>
                <HipButton
                  viewMode="large-icon"
                  image={button.attributes.image as HipIconType}
                  size={deviceSize}
                  // color={button.attributes.color}
                  fill={button.attributes.fill}
                />
                <ButtonText
                  textStyles={style.textStyles}
                  id={room.id}
                  name={room.name}
                  bed={button.bed}
                  accessKey={button.accessKey}
                  user_name={button.user_name}
                />
              </div>
            ))
          : rcuInfo.map((button, index) => (
              <Box key={index} m={2} sx={style.deviceStyle}>
                <HipButton
                  viewMode="large-icon"
                  image={button.attributes.image as HipIconType}
                  size={deviceSize}
                  // color={button.attributes.color}
                  fill={button.attributes.fill}
                />
                <ButtonText
                  textStyles={style.textStyles}
                  id={room.id}
                  name={room.name}
                  bed={button.bed}
                  accessKey={button.accessKey}
                  user_name={button.user_name}
                />
              </Box>
            ))}
      </Box>
    </div>
  )
}

export default RoomButton
