import { call, put } from '@redux-saga/core/effects'
import {
  INotificationTokenRequest,
  IPushSubscriptionRequest,
  IUserInfo,
  LoginInfoRequest,
} from '../../api/types'
import axios from 'axios'
import { getLoginInfo, postNotificationToken, postPushSubscription } from '../../api/login/login'
import { userInfoError, userInfoSuccess } from '../actions/user/actions'
import { Action } from './root'

export function* userInfo({ payload }: Action<LoginInfoRequest>) {
  try {
    const response: IUserInfo = yield call(getLoginInfo, payload.username)
    yield put(userInfoSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userInfoError(error.response.data as unknown))
    } else {
      yield put(userInfoError(error))
      throw error
    }
  }
}

export function* userNotificationTokenRequest({ payload }: Action<INotificationTokenRequest>) {
  yield call(postNotificationToken, payload)
}

export function* userPushSubscriptionRequest({ payload }: Action<IPushSubscriptionRequest>) {
  yield call(postPushSubscription, payload)
}
