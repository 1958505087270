import { blue } from '@mui/material/colors'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { CommonContent } from '../../components/CommonContent'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Edit, Delete, MeetingRoom } from '@mui/icons-material'
import { Ward } from '../types'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: 'sm',
  },
  head: {
    backgroundColor: blue[500],
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  table: {},
  click_cancelar: {
    '&:hover': {
      backgroundColor: '#3251AD',
      color: 'white',
    },
  },
  click_delete: {
    '&:hover': {
      backgroundColor: '#FF6466',
      color: 'white',
    },
  },
}))

export const WardManager = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  //const { enqueueSnackbar } = useSnackbar()

  const [rows] = useState<Ward[]>([])
  // const [open, setOpen] = useState(false)
  // const [rowToDelete, setRowToDelete] = useState<Partial<Ward>>({})

  const handleDelete = (ev: any, row: Ward) => {
    console.log(ev, row)
  }
  return (
    <CommonContent title={t('wards')} titleIcon={<MeetingRoom style={{ color: 'white' }} />}>
      {' '}
      <Box mb={4}>
        <Button
          data-e2e="ward-btn-add"
          component={Link}
          to="/wards/add"
          variant="contained"
          color="success"
          className={classes.button}
          startIcon={<MeetingRoom />}
        >
          {t('wards_add')}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Divider />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"> {t('name')}</TableCell>
              <TableCell align="center">{t('desc')}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} data-e2e={'wards-row-' + String(row.id)}>
                <TableCell align="center" component="th" scope="row" data-e2e="wards-row-cell-name">
                  {row.name}
                </TableCell>
                <TableCell align="center" data-e2e="wards-row-cell-description">
                  {row.description}
                </TableCell>
                <TableCell align="right" data-e2e="wards-row-cell-actions">
                  <Grid>
                    <Button
                      component={Link}
                      to={'/wards/' + row.id}
                      data-e2e="wards-row-cell-actions-edit"
                    >
                      <Edit color="primary" />
                    </Button>
                    {row.name !== 'messages' && (
                      <Button
                        variant="text"
                        onClick={(ev) => handleDelete(ev, row)}
                        data-e2e="wards-row-cell-actions-delete"
                      >
                        <Delete color="error" />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* dialog */}
    </CommonContent>
  )
}
