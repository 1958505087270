import React, { useState } from 'react'
import { TextField, Collapse, Checkbox, FormControlLabel, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  password: string
  adduser: boolean
  'data-e2e'?: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const PasswordField = (props: IProps) => {
  const [passw, setPassw] = useState(false)
  const { t } = useTranslation()

  const PasswChange = () => {
    const element: any = document.getElementById('cbxPassw')
    setPassw(element.checked)
  }

  if (props.adduser) {
    return (
      <div>
        <TextField
          data-e2e={props['data-e2e']}
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="password"
          name="password"
          label={t('password')}
          value={props.password}
          onChange={props.handleChange}
          id="standard-password-input"
          inputProps={{
            form: {
              autocomplete: 'off',
            },
          }}
          variant="outlined"
        />
      </div>
    )
  } else {
    return (
      <Box style={{ marginBottom: '30px' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="cbxPassw"
              onChange={PasswChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('change_password')}
        />
        <Collapse in={passw}>
          <TextField
            style={{ paddingBottom: 20 }}
            fullWidth
            type="password"
            name="password"
            label={t('password')}
            value={props.password}
            onChange={props.handleChange}
            id="standard-password-input"
            autoComplete="current-password"
            variant="outlined"
          />
        </Collapse>
      </Box>
    )
  }
}
