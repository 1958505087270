import axios from 'axios'
import { getAPIService } from '../../getServerHost'

export const getHipUsersByProfiles = async (ids: number[]) => {
  let profiles = ''
  if (ids) {
    ids.map((id) => {
      profiles = profiles.concat(`profile_id=${id}&`)
    })
  }
  const response = await axios.get(`${getAPIService}/profile/users?${profiles}`)
  return response.data
}
