import { createAction } from '@reduxjs/toolkit'
import {
  ROOMS_REQUEST,
  ROOMS_SUCCESS,
  ROOMS_ERROR,
  ROOM_CREATE_REQUEST,
  ROOM_CREATE_SUCCESS,
  ROOM_CREATE_ERROR,
  ROOM_DELETE_REQUEST,
  ROOM_DELETE_SUCCESS,
  ROOM_DELETE_ERROR,
  ROOM_UPDATE_REQUEST,
  ROOM_UPDATE_SUCCESS,
  ROOM_UPDATE_ERROR,
  ROOM_DETAIL_REQUEST,
  ROOM_DETAIL_SUCCESS,
  ROOM_DETAIL_ERROR,
  ROOM_CLEAN_ERROR_REQUEST,
  ROOM_CLEAN_SUCCESS_REQUEST,
} from './actionTypes'
import { Room, roomId, PageSet, UpdatedRoomdData, deletedItem } from '../../types'
import { PaginatedResponse } from '../../../types'

export const roomsRequest = createAction<PageSet>(ROOMS_REQUEST)
export const roomsSuccess = createAction<PaginatedResponse>(ROOMS_SUCCESS)
export const roomsError = createAction<any>(ROOMS_ERROR)

export const roomCreateRequest = createAction<UpdatedRoomdData>(ROOM_CREATE_REQUEST)
export const roomCreateSuccess = createAction<Room>(ROOM_CREATE_SUCCESS)
export const roomCreateError = createAction<any>(ROOM_CREATE_ERROR)

export const roomDeleteRequest = createAction<roomId>(ROOM_DELETE_REQUEST)
export const roomDeleteSuccess = createAction<deletedItem>(ROOM_DELETE_SUCCESS)
export const roomDeleteError = createAction<any>(ROOM_DELETE_ERROR)

export const roomDetailRequest = createAction<roomId>(ROOM_DETAIL_REQUEST)
export const roomDetailSuccess = createAction<Room>(ROOM_DETAIL_SUCCESS)
export const roomDetailError = createAction<any>(ROOM_DETAIL_ERROR)

export const roomUpdateRequest = createAction<UpdatedRoomdData>(ROOM_UPDATE_REQUEST)
export const roomUpdateSuccess = createAction<Room>(ROOM_UPDATE_SUCCESS)
export const roomUpdateError = createAction<any>(ROOM_UPDATE_ERROR)

export const roomCleanSuccessRequest = createAction(ROOM_CLEAN_SUCCESS_REQUEST)
export const roomCleanErrorRequest = createAction(ROOM_CLEAN_ERROR_REQUEST)
