import axios from 'axios'
import { getAPIService } from '../../../getServerHost'
import { RadioFormValues } from '../../../radio_manager/RadiosForm'

export const radioCreate = async (payload: RadioFormValues) => {
  const { name, url } = payload
  const response = await axios.post(`${getAPIService}/radios`, {
    name,
    url,
  })
  return response.data
}
